import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Tooltip,
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input,
  Spinner
} from "reactstrap";
import axios from "axios";
import unicreditLogo from "./../../assets/unicreditLogo.png";

const BASE_URL = window.__RUNTIME_CONFIG__.BASE_URL;

function AdvisorRedirect(props) {
  const r4aID = props.match.params.id;
  localStorage.setItem("r4aID", r4aID);
  // props.history.push("/advisorRoom");
  const [problemData, setProblemData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const [advisorRedirectText, setAdvisorRedirectText] = React.useState("");
  const [error, setError] = React.useState("");

  const [newAdvisorId, setNewAdvisorId] = React.useState("");

  useEffect(() => {
    axios
      .get(BASE_URL + "meeting/" + r4aID + "/info?role=ADVISOR", {})
      .then((res) => {
        setAdvisorRedirectText(res.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 403) {
            setProblemData(error.response.data);
          }
        } else if (error.request) {
          setProblemData("Service is unavailable!");
        }
      });
  }, []);

  function goToMeetingNewAdvisor() {
    if (newAdvisorId == "") {
      setError("Unicredit ID its empty");
      return;
    }
    axios
      .post(
        BASE_URL + "identity/advisor/" + r4aID,
        { "unicreditAdvisorId": newAdvisorId.replace(/\s/g, "").toUpperCase() },
        {}
      )
      .then((res) => {
        localStorage.setItem("r4asessionid", res.headers["r4asessionid"]);
        props.history.push("/advisorRoom");
      })
      .catch(function (error) {
        setError("There was an error starting the meeting");
      });
  }

  if (isLoading) {
    return (
      <div
        className="redirectPage"
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className="redirectPage__content mb-4">
          <img src={unicreditLogo} alt="Unicredit Logo" />
        </div>
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (problemData != null) {
    return (
      <div className="Client clientWaitingWrapper">
        <div className="clientWaiting">
          <img style={{ width: "100%" }} src={unicreditLogo} />
          <br />
          <br />

          <p className="text-center">
            <b>{problemData}</b>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Modal isOpen={true} fade={false}>
        <ModalHeader className="px-4">
          <div className="redirectPage__content">
            <img src={unicreditLogo} alt="Unicredit Logo" />
          </div>
        </ModalHeader>{" "}
        <ModalBody className="p-3">
          {advisorRedirectText}
          <br />
          <div className="text-danger mt-4">{error}</div>
          <input
            type="text"
            class="form-control"
            id="advisorNewId"
            placeholder="Enter your Unicredit ID"
            value={newAdvisorId}
            onChange={(e) => setNewAdvisorId(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={(e) => goToMeetingNewAdvisor()}>
            Submit new ID
          </Button>
          <Button
            color="primary"
            onClick={(e) => {
              localStorage.setItem("r4asessionid", "current");
              props.history.push("/advisorRoom");
            }}
          >
            Yes, that's me
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AdvisorRedirect;
