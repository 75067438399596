import React, { useState, useEffect, useRef } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import fileHistoryClosed from "./../../assets/fileHistoryClosed.png";
import shareIcon from "./../../assets/shareIcon.png";
import stopShareIcon from "./../../assets/stopShareIcon.png";

import zoomInIcon from "./../../assets/zoomInIcon.png";
import zoomOutIcon from "./../../assets/zoomOutIcon.png";
import closePdfIcon from "./../../assets/closePdfIcon.png";
import showMouseIcon from "./../../assets/showMouseIcon.png";
import previousIcon from "./../../assets/previousIcon.png";
import nextIcon from "./../../assets/nextIcon.png";
import rotateLeftIcon from "./../../assets/rotate_left.png";
import rotateRightIcon from "./../../assets/rotate_right.png";
import addToHistoryIcon from "./../../assets/add_to_history.png";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true
};

export default function PdfViewerAdvisor(props) {
  const file = props.file;

  let layerOverPDFViewer = useRef(null);
  let pdfViewerHTML = useRef(null);
  let pdfViewerDiv = useRef(null);
  let pdfViewer = useRef(null);
  const [isSharing, setIsSharing] = useState(false);

  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  let myPdfViewerContainer = useRef(null);
  var eventBus = new window.pdfjsViewer.EventBus();

  let pdfZoom = useRef(1.0);
  let pdfRotation = useRef(props.rotate);

  let customPageInput = useRef();

  useEffect(() => {
    if ((pdfViewer.current == null) & (pdfViewerHTML.current != null)) {
      pdfViewer.current = new window.pdfjsViewer.PDFSinglePageViewer({
        container: pdfViewerHTML.current,
        eventBus: eventBus,
        textLayerFactory: new window.pdfjsViewer.DefaultTextLayerFactory()
      });

      pdfViewer.current.currentPageNumber = props.pageNumber;
      pdfViewer.current.currentScaleValue = "page-width";

      pdfViewerHTML.current.addEventListener("scroll", function (e) {
        console.log("props.scrollLeft", pdfViewerHTML.current.scrollLeft);
        console.log("props.scrollTop", pdfViewerHTML.current.scrollTop);
        console.log("props.scrollHeight", pdfViewerHTML.current.scrollHeight);

        props.actionPdfScroll({
          scrollTop: pdfViewerHTML.current.scrollTop,
          scrollLeft: pdfViewerHTML.current.scrollLeft,
          scrollHeight: pdfViewerHTML.current.scrollHeight,
          scrollWidth: pdfViewerHTML.current.scrollWidth
        });
      });

      eventBus.on("pagesinit", function () {
        pdfViewer.current.currentScaleValue = "page-width";
        pdfViewer.current.currentPageNumber = props.pageNumber;
        setPagesCount(pdfViewer.current.pagesCount);

        //pdfViewer.currentScale = pdfViewerHTML.clientWidth / pdfViewer.container.scrollWidth;
      });

      let fileReader = new FileReader();

      fileReader.onload = function () {
        let typedarray = new Uint8Array(this.result);

        //replaced the old function with the new api
        const loadingTask = window.pdfjsLib.getDocument(typedarray);
        loadingTask.promise.then((pdfDocument) => {
          pdfViewer.current.setDocument(pdfDocument);
          pdfViewer.current.currentScaleValue = "page-width";
        });
      };
      fileReader.readAsArrayBuffer(file);
    }

    // pdfViewer.current.setDocument(file);
    setPagesCount(pdfViewer.current.pagesCount);
  }, [pdfViewerHTML.current]);

  useEffect(() => {
    if (pdfViewer.current != null) {
      setPagesCount(pdfViewer.current.pagesCount);
    }
  }, [pdfViewer.current]);

  window.onclick = function (event) {
    console.log(event);
    if (event.target === document.getElementById("myPdfViewerContainer")) {
      props.actionMinimizePdf();
    }
  };
  console.log("props.PageNumber", props.pageNumber);
  console.log("clientHeight", window.screen.height);
  // console.log("clientWidth", document.querySelector('#viewerContainer').clientWidth);

  let pdfCurrentPageNum = useRef(props.pageNumber);

  function setCustomPage(e) {
    setCurrentPage(e.target.value);
    console.log("trigger setCustomPage");
    e.preventDefault();
    let pageNumberLocal = parseInt(e.target.value);
    if (pageNumberLocal < 1) {
      return;
    }
    if (pageNumberLocal > pdfViewer.current.pagesCount) {
      return;
    }

    pdfViewer.current.currentPageNumber = pageNumberLocal;
    pdfViewer.current.currentScaleValue = "page-width";
    setCurrentPage(pageNumberLocal);
    props.actionPageChange(pageNumberLocal);
    pdfCurrentPageNum.current = pageNumberLocal;
  }

  function onPrevPage() {
    if (pdfCurrentPageNum.current <= 1) {
      return;
    }

    pdfCurrentPageNum.current--;
    pdfViewer.current.currentPageNumber = pdfCurrentPageNum.current;
    pdfViewer.current.currentScaleValue = "page-width";
    props.actionPageChange(pdfCurrentPageNum.current);
    customPageInput.current.value = pdfCurrentPageNum.current;
    setCurrentPage(pdfCurrentPageNum.current);
  }

  function onNextPage() {
    if (pdfCurrentPageNum.current >= pdfViewer.current.pagesCount) {
      return;
    }

    pdfCurrentPageNum.current++;
    pdfViewer.current.currentPageNumber = pdfCurrentPageNum.current;
    props.actionPageChange(pdfCurrentPageNum.current);
    pdfViewer.current.currentScaleValue = "page-width";
    customPageInput.current.value = pdfCurrentPageNum.current;
    setCurrentPage(pdfCurrentPageNum.current);
  }

  function zoomInAction() {
    if (pdfZoom.current < 2.0) {
      setTransformOrigin();

      pdfViewerDiv.current.style.transform =
        "scale(" +
        (pdfZoom.current + 0.1) +
        ") rotate(" +
        pdfRotation.current +
        "deg)";
      pdfZoom.current = pdfZoom.current + 0.1;
      setTransformOrigin();
      var parent = document.querySelector("#viewerContainer");
      parent.scrollLeft = (parent.scrollWidth - parent.clientWidth) / 2;

      props.actionZoomPdf(pdfZoom.current);
    }
  }

  function setTransformOrigin() {
    if (pdfRotation.current === 0) {
      pdfViewerDiv.current.style.transformOrigin = "0% 0% 0px";
      // pdfViewerDiv.current.style.transformOrigin = "center center";
    } else {
      pdfViewerDiv.current.style.transformOrigin = "center center";
    }
  }

  function rotateLeft() {
    if (pdfRotation.current === 0) {
      pdfRotation.current = 270;
    } else {
      pdfRotation.current = pdfRotation.current - 90;
    }

    pdfViewerDiv.current.style.transform =
      "scale(" + pdfZoom.current + ") rotate(" + pdfRotation.current + "deg)";
    setTransformOrigin();

    props.actionRotatePdf(pdfRotation.current);
  }

  function rotateRight() {
    if (pdfRotation.current === 270) {
      pdfRotation.current = 0;
    } else {
      pdfRotation.current = pdfRotation.current + 90;
    }

    pdfViewerDiv.current.style.transform =
      "scale(" + pdfZoom.current + ") rotate(" + pdfRotation.current + "deg)";
    setTransformOrigin();

    props.actionRotatePdf(pdfRotation.current);
  }

  function zoomOutAction() {
    if (pdfZoom.current > 0.4) {
      setTransformOrigin();

      pdfViewerDiv.current.style.transform =
        "scale(" +
        (pdfZoom.current - 0.1) +
        ") rotate(" +
        pdfRotation.current +
        "deg)";
      setTransformOrigin();
      pdfZoom.current = pdfZoom.current - 0.1;
      props.actionZoomPdf(pdfZoom.current);
      var parent = document.querySelector("#viewerContainer");
      parent.scrollLeft = (parent.scrollWidth - parent.clientWidth) / 2;
    }
  }

  if (layerOverPDFViewer.current != null && props.isAdvisorSelection == false) {
    layerOverPDFViewer.current.style.display = "block";
    layerOverPDFViewer.current.style.height =
      pdfViewerHTML.current.clientHeight + "px";
    layerOverPDFViewer.current.style.width =
      pdfViewerHTML.current.clientWidth + "px";
    layerOverPDFViewer.current.style.top =
      pdfViewerHTML.current.offsetTop + "px";
    layerOverPDFViewer.current.style.left =
      pdfViewerHTML.current.offsetLeft + "px";
  }

  if (layerOverPDFViewer.current != null && props.isAdvisorSelection == true) {
    layerOverPDFViewer.current.style.display = "none";
  }

  useEffect(() => {
    pdfViewerDiv.current.style.transform =
      "scale(" + pdfZoom.current + ") rotate(" + pdfRotation.current + "deg)";
    setTransformOrigin();

    setPagesCount(pdfViewer.current.pagesCount);
  }, [pdfViewerDiv.current]);

  return (
    <div
      id="myPdfViewerContainer"
      className=""
      // data-html2canvas-ignore
      style={{ border: isSharing ? "4px #EA5C4D solid" : "" }}
    >
      <div>
        <div className="pdfWrapperButtons">
          {/* <span
            className="ui-component-toggle--label"
            style={{ color: "whitesmoke" }}
          >
            Text Selection
          </span> */}
          {/* {props.isAdvisorSelection == false ? (<button onClick={(e) => props.setWhoCanSelect(true)}>
            Set advisor to select
          </button>) : (          <button onClick={(e) => props.setWhoCanSelect(false)}>
            Set customer to select
          </button>)} */}
          <Row>
            <Col xl="6" className="text-left">
              {isSharing == false ? (
                <button
                  className="btnWrapper iconElementPdfBtn"
                  onClick={(e) => {
                    if (props.isAdvisorConnected == true) {
                      props.triggerSendPdfToCustomer();
                      setIsSharing(true);
                      pdfViewerDiv.current.style.transform = "scale(1)";
                      pdfZoom.current = 1;
                    }
                  }}
                  style={{
                    opacity: props.isAdvisorConnected != true ? "0.5" : "1"
                  }}
                >
                  <img
                    className="iconElementPdf clickableImg"
                    src={shareIcon}
                    style={{ display: "block" }}
                    data-html2canvas-ignore
                    alt="Open File History"
                  />
                  <span className="iconElementPdfText">Start Sharing</span>
                </button>
              ) : (
                <button
                  className="btnWrapper iconElementPdfBtn"
                  onClick={(e) => {
                    props.actionStopPdfShare();
                    setIsSharing(false);
                    pdfViewerDiv.current.style.transform = "scale(1)";
                    pdfZoom.current = 1;
                  }}
                >
                  <img
                    className="iconElementPdf clickableImg"
                    src={stopShareIcon}
                    style={{ display: "block" }}
                    data-html2canvas-ignore
                    alt="Open File History"
                  />
                  <span
                    className="iconElementPdfText"
                    style={{ color: "#EA5C4D" }}
                  >
                    Stop Sharing
                  </span>
                </button>
              )}

              {props.isMouse ? (
                <>
                  <button
                    className="btnWrapper iconElementPdfBtn"
                    onClick={(e) => props.showMouse(false)}
                  >
                    <img
                      className="iconElementPdf clickableImg"
                      src={showMouseIcon}
                      style={{ display: "block" }}
                      data-html2canvas-ignore
                      alt="Open File History"
                    />
                    <span className="iconElementPdfText">Hide Pointer</span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btnWrapper iconElementPdfBtn"
                    onClick={(e) => {
                      if (props.isAdvisorConnected == true) {
                        props.showMouse(true);
                      }
                    }}
                    style={{
                      opacity: props.isAdvisorConnected != true ? "0.5" : "1"
                    }}
                  >
                    <img
                      className="iconElementPdf clickableImg"
                      src={showMouseIcon}
                      style={{ display: "block" }}
                      data-html2canvas-ignore
                      alt="Open File History"
                    />
                    <span className="iconElementPdfText">Show Pointer</span>
                  </button>
                </>
              )}
              <button
                className="btnWrapper iconElementPdfBtn"
                onClick={(e) => props.actionAddToHistory()}
              >
                <img
                  className="iconElementPdf clickableImg"
                  src={addToHistoryIcon}
                  style={{ display: "block" }}
                  data-html2canvas-ignore
                  alt="Open File History"
                />
                <span className="iconElementPdfText">Add to history</span>
              </button>
            </Col>

            <Col xl="6" className="text-right" style={{ textAlign: "right" }}>
              <button
                className="btnWrapper iconElementPdfBtn"
                onClick={(e) => rotateLeft()}
              >
                <img
                  className="iconElementPdf clickableImg"
                  src={rotateRightIcon}
                  style={{ display: "block", transform: "scaleX(-1)" }}
                  data-html2canvas-ignore
                  alt="Rotate Left"
                />
                <span className="iconElementPdfText">Rotate Left</span>
              </button>
              <button
                className="btnWrapper iconElementPdfBtn"
                onClick={(e) => rotateRight()}
              >
                <img
                  className="iconElementPdf clickableImg"
                  src={rotateRightIcon}
                  style={{ display: "block" }}
                  data-html2canvas-ignore
                  alt="Rotate Right"
                />
                <span className="iconElementPdfText">Rotate Right</span>
              </button>
              <button
                className="btnWrapper iconElementPdfBtn"
                onClick={(e) => zoomInAction()}
              >
                <img
                  className="iconElementPdf clickableImg"
                  src={zoomInIcon}
                  style={{ display: "block" }}
                  data-html2canvas-ignore
                  alt="Open File History"
                />
                <span className="iconElementPdfText">Zoom In</span>
              </button>
              <button
                className="btnWrapper iconElementPdfBtn pdfButtonsLastElementWithBorderRight"
                onClick={(e) => zoomOutAction()}
              >
                <img
                  className="iconElementPdf clickableImg"
                  src={zoomOutIcon}
                  style={{ display: "block" }}
                  data-html2canvas-ignore
                  alt="Open File History"
                />
                <span className="iconElementPdfText">Zoom Out</span>{" "}
              </button>
              <button
                className="btnWrapper iconElementPdfBtn"
                id="prev"
                onClick={(e) => onPrevPage()}
              >
                <img
                  className="iconElementPdf clickableImg"
                  src={previousIcon}
                  style={{ display: "block" }}
                  data-html2canvas-ignore
                  alt="Open File History"
                />
                <span className="iconElementPdfText">Previous</span>
              </button>
              <button
                className="btnWrapper iconElementPdfBtn pdfButtonsLastElementWithBorderRight"
                id="next"
                onClick={(e) => onNextPage()}
              >
                <img
                  className="iconElementPdf clickableImg"
                  src={nextIcon}
                  style={{ display: "block" }}
                  data-html2canvas-ignore
                  alt="Open File History"
                />
                <span className="iconElementPdfText">Next</span>
              </button>

              <button
                className="btnWrapper iconElementPdfBtn"
                onClick={(e) => props.actionClosePdf()}
              >
                <img
                  className="iconElementPdf clickableImg"
                  src={closePdfIcon}
                  style={{ display: "block" }}
                  data-html2canvas-ignore
                  alt="Open File History"
                />
                <span className="iconElementPdfText">Close</span>
              </button>
            </Col>
            <Col xl="12" className="text-left" style={{ textAlign: "left" }}>
              <div className="pdfFileNameText">
                {file.name}{" "}
                <Input
                  style={{ width: "40px", display: "inline" }}
                  type="text"
                  value={currentPage === 0 ? 1 : currentPage}
                  onChange={(e) => setCustomPage(e)}
                  ref={customPageInput}
                />{" "}
                / {pagesCount}
              </div>
            </Col>
          </Row>

          {/* <button onClick={(e) => props.actionMinimizePdf()}>
            Minimize Pdf
          </button> */}

          {/* <div className="ui-component-toggle ui-layout-flex" style={{margin: "0"}}>
					<label htmlFor="highlight__you" className="ui-component-toggle--label">You</label>
					<label htmlFor="highlight__peer" className="ui-component-toggle--label" style={{cursor: "default"}}>Peer</label>
			</div> */}
        </div>
      </div>
      <div id="viewerContainer" className="pdfContainer" ref={pdfViewerHTML}>
        <div id="viewer" className="pdfViewer" ref={pdfViewerDiv}></div>
        <div
          id="layerOver"
          style={{ display: "none" }}
          ref={layerOverPDFViewer}
        ></div>
      </div>
    </div>
  );
}
