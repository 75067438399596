import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import attention from "./../../assets/attention.png";
import unicreditLogoWhite from "./../../assets/unicreditLogoWhite.png";

export default function ModalFileErr(props) {
  return (
    <Modal
      isOpen={props.modalFileErr}
      toggle={props.toggleModalFileErr}
      className="modalWrapper"
    >
      <ModalBody>
        <div className="modal-top-wrapper">
          <img src={unicreditLogoWhite} style={{ width: "30%" }} />
          <br />
        </div>
        <img src={attention} style={{ width: "100px" }} />
        <br />
        <b>Attention!</b>
        <br />
        This File can't be shared
        <br />
        <br />
        <Button
          color="primary confirmBtn"
          onClick={(e) => {
            props.toggleModalFileErr();
          }}
        >
          Okay, I got it
        </Button>
      </ModalBody>
    </Modal>
  );
}
