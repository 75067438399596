import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Link, Route } from 'react-router-dom';
import Client from './modules/client/client';
import Advisor from './modules/advisor/advisor';
import AdvisorWebexRedirect from './modules/advisor/advisorWebexRedirect';
import AdvisorRedirect from './modules/advisor/advisorRedirect';
import ClientRedirect from './modules/client/clientRedirect';

import NotFound from './modules/NotFound';
import './assets/mainNew.css';

import 'bootstrap/dist/css/bootstrap.min.css';


function Dashboard() {

return (
  <div>
  </div>
    );

}

ReactDOM.render(
    <Router>
      <div>
        <main>
          <Route exact path="/" component={Dashboard} />

          <Route path="/customer/:id" render={(props) => <ClientRedirect {...props} />} />
          <Route path="/customerRoom" render={(props) => <Client {...props} />} />


          <Route path="/advisor/:id" render={(props) => <AdvisorRedirect {...props} />} />
          <Route path="/advisorRoom" render={(props) => <Advisor {...props} />} />


          <Route path="/redirect/advisor" render={(props) => <AdvisorWebexRedirect {...props} />} />
          <Route path="/404" component={NotFound} />
        </main>
      </div>
    </Router>,
    document.getElementById('root')
  )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
