import React, { useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Tooltip,
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input,
  Spinner
} from "reactstrap";
import "./../redirectPage.css";
import axios from "axios";
import unicreditLogo from "./../../assets/unicreditLogo.png";

const BASE_URL = window.__RUNTIME_CONFIG__.BASE_URL;

function ClientRedirect(props) {
  console.log(
    props.location.search.split("=")[
      props.location.search.split("=").length - 1
    ]
  );
  const r4aID = props.match.params.id;
  localStorage.setItem("r4aIDCustomer", r4aID);
  const [isAgreeTerms, setIsAgreeTerms] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [problemData, setProblemData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  // props.history.push("/customerRoom");

  useEffect(() => {
    axios
      .get(BASE_URL + "meeting/" + r4aID + "/info?role=CUSTOMER", {})
      .then((res) => {
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        if (error.response) {
          if (error.response.status == 403) {
            setProblemData(error.response.data);
          }
        } else if (error.request) {
          setProblemData("Service is unavailable!");
        }
      });
  }, []);

  function goToMeeting() {
    setError(null);

    if (!isAgreeTerms) {
      setError("You must agree to the terms and conditions");
      return;
    }

    props.history.push("/customerRoom");

  }

  if (isLoading) {
    return (
      <div
        className="redirectPage"
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className="redirectPage__content mb-4">
          <img src={unicreditLogo} alt="Unicredit Logo" />
        </div>
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (problemData != null) {
    return (
      <div className="Client clientWaitingWrapper">
        <div className="clientWaiting">
          <img style={{ width: "100%" }} src={unicreditLogo} />
          <br />
          <br />

          <p className="text-center">
            <b>{problemData}</b>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Modal isOpen={true} fade={true}>
        <ModalHeader className="px-4">
          <div className="redirectPage__content">
            <img src={unicreditLogo} alt="Unicredit Logo" />
          </div>
        </ModalHeader>
        <ModalBody className="p-4">
          <span className="text-danger">{error}</span>
          {error != null ? (
            <>
              <br />
            </>
          ) : (
            <></>
          )}
          <label
            className="form-check-label ps-2"
            htmlFor="agreeTerms"
            style={{ cursor: "pointer" }}
          >
           Con riferimento all’informativa per il trattamento dei dati personali{" "},
            <a href="https://google.com" target="blank">link 
            </a>
             {" "}ad UniCredit S.p.A. per il trattamento dei dati relativi alla mia immagine,
             le registrazioni vocali e, laddove attivo,
              al trattamento dei dati personali relativi al video,
               per le finalità strettamente connesse e strumentali all’esecuzione dell’appuntamento mediante videochiamata
          </label>
          <input
            className="form-check-input"
            id="agreeTerms"
            style={{ cursor: "pointer" }}
            type="checkbox"
            defaultChecked={isAgreeTerms}
            checked={isAgreeTerms}
            onChange={(e) => {
              setIsAgreeTerms(!isAgreeTerms);
            }}
          />
          <label>{" "} Do il consenso</label>
        </ModalBody>

        <ModalFooter className="px-4">
          <Button color="primary" onClick={(e) => goToMeeting()}>
            Continue to Meeting
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ClientRedirect;
