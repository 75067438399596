export function selectNodes(nodes, from, to) {
  let range, selection;
  if (window.getSelection && document.createRange) {
    range = document.createRange();
    if (nodes.length === 1) {
      range.setStart(nodes[0].firstChild, from);
      range.setEnd(nodes[0].firstChild, to);
    } else {
      range = document.createRange();
      range.setStart(nodes[0].firstChild, from);
      range.setEnd(nodes[1].firstChild, to);
    }
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  } else if (document.selection && document.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(nodes);
    range.select();
  }
};

export function onStateChange(pc, event) {
  if (pc) {
    console.log("State: ", pc.connectionState);
    console.log("State change event: ", event);
    if (pc.connectionState === "failed") {
      pc.restartIce();
    }
  }
};

export function onIceStateChange(pc, event) {
  if (pc) {
    console.log("ICE state: ", pc.iceconnectionState);
    console.log("ICE state change event: ", event);
    if (pc.iceconnectionState === "failed") {
      pc.restartIce();
    }
  }
};

export function handleICECandidateEvent(e, sendMessage) {
  console.log(e);
  if (e.candidate) {
      sendMessage("ADVISOR_ICE_CANDIDATE_TO_WS", {
        iceCandidate: e.candidate,
      });
  }
}

export function handleReceiveMessage(event) {
  console.log("received on WEBRTC", event.data);
}

export function handleReceiveChannelStatusChange(event) {
    console.log("Receive channel's status has change");
}

export function receiveChannelCallback(event, receiveChannel, receiveChannelEvent, handleReceiveEventMessage) {
  if (event.channel.label === "sendContent") {
    receiveChannel = event.channel;
    receiveChannel.onmessage = handleReceiveMessage;
    receiveChannel.onopen = handleReceiveChannelStatusChange;
    receiveChannel.onclose = handleReceiveChannelStatusChange;
  } else if (event.channel.label === "sendEventContent") {
    receiveChannelEvent = event.channel;
    receiveChannelEvent.onmessage = (e) => handleReceiveEventMessage(e);
    receiveChannelEvent.onopen = handleReceiveChannelStatusChange;
    receiveChannelEvent.onclose = handleReceiveChannelStatusChange;
  }
}

export function handleNewICECandidateMsg(incoming, peer) {
  const candidate = new RTCIceCandidate(incoming);

  peer.addIceCandidate(candidate).catch((e) => console.log(e));
}

export function handleAnswer(sdp, peer) {
  peer.setRemoteDescription(sdp).catch((e) => console.log(e));
}

export function sendPdfData(localPDFFile, sendPdfChannel) {
  const file = localPDFFile;
  const chunkSize = 124000;

  console.log(
    `File is ${[file.name, file.size, file.type, file.lastModified].join(
      " "
    )}`
  );

  let fileReader = new FileReader();
  let offset = 0;
  fileReader.addEventListener("error", (error) =>
    console.error("Error reading file:", error)
  );
  fileReader.addEventListener("abort", (event) =>
    console.log("File reading aborted:", event)
  );
  fileReader.addEventListener("load", (e) => {
    console.log("FileRead.onload ", e);
    sendPdfChannel.send(e.target.result);
    offset += e.target.result.byteLength;

    if (offset < file.size) {
      readSlice(offset);
    } else {
      sendPdfChannel.send("End");
    }
  });
  const readSlice = (o) => {
    console.log("readSlice ", o);
    const slice = file.slice(offset, o + chunkSize);
    fileReader.readAsArrayBuffer(slice);
  };
  readSlice(0);
}