import React, { useState, useEffect, useRef } from "react";
import "./../../components/chat/chat.css";
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge
} from "reactstrap";
var today = new Date();

function FilesHistoryElements(props) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };


  return (
    <div>
    <button className="btnWrapper btnFileHistoryElement" onClick={(e) => props.openArchivePdfFile(props.index)} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {props.index + 1}. <b>{isHovering === false ? props.name.substring(0,25) + "..." : props.name}</b>
    </button>
    <br />
  </div>
  );
}

export default FilesHistoryElements;
