import React, { useState, useEffect, useRef } from "react";
import "./chat.css";
import sendIcon from "./../../assets/send.png";
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
var today = new Date();

export default function Chat(props) {
  const [message, setMessage] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (message == "") {
      return;
    }
    props.sendMessage("ALL_SEND_CHAT_MESSAGE_TO_WS", { message: message });
    setMessage("");
  };

  return (
    <div className="">
      <div className="chat">
        <div className="helpChatWrapper">
          <Row>
            <Col xl="2"></Col>
            <Col xl="8" className="text-center">
              Help Chat
            </Col>
            <Col xl="2" className="text-right">
              <button className="chat-close" onClick={(e) => props.closeChat()}>
                X
              </button>
            </Col>
          </Row>
        </div>
        <div className="contact bar">
          <div className="name"></div>
          <div className="seen">
            <b>
              Today at {("0" + today.getHours()).slice(-2)}:
              {(today.getMinutes() < 10 ? "0" : "") + today.getMinutes()}
            </b>
          </div>
        </div>
        <div className="messages" id="chat">
          {props.chatMessages.map((element, i) => {
            if (element.sentBy == props.name) {
              return (
                <div key={i} className="message self">
                  {element.msg}
                </div>
              );
            } else {
              return (
                <div key={i} className="message remote">
                  <span className="chatName">{element.sentBy} </span>
                  <br />
                  {element.msg}
                </div>
              );
            }
          })}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input" >
            {props.isAdvisor == true ? (
              <input
                placeholder="Type your message here!"
                type="text"
                value={message}
                maxLength="30" style={{color:'black'}}
                onChange={(e) => setMessage(e.target.value)}
              />
            ) : (
              <input
                placeholder="Type your message here!"
                type="text"
                value={message} style={{color:'black'}}
                onChange={(e) => setMessage(e.target.value)}
              />
            )}

            <button type="submit" className="buttonWrapper">
              {" "}
              <img style={{ width: "80%" }} src={sendIcon} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
