import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import warningImg from "./../../assets/warning.png";
import unicreditLogoWhite from "./../../assets/unicreditLogoWhite.png";

export default function ModalShareScreen(props) {
  return (
    <Modal
      isOpen={props.modalShareScreen}
      toggle={props.toggleModalShareScreen}
      className="modalWrapper"
    >
      <ModalBody>
        <div className="modal-top-wrapper">
          <img src={unicreditLogoWhite} style={{ width: "30%" }} />
          <br />
        </div>
        <img src={warningImg} style={{ width: "100px" }} />
        <br />
        <b>Warning</b>
        <br />
        Are you sure you want to share your screen?
        <br />
        <br />
        <Button
          color="secondary cancelBtn"
          onClick={(e) => {
            props.toggleModalShareScreen();
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary confirmBtn"
          onClick={(e) => {
            props.toggleModalShareScreen();
            props.startShareScreen();
          }}
        >
          Confirm
        </Button>
      </ModalBody>
    </Modal>
  );
}
