
function AdvisorWebexRedirect(props) {
    const hashParams = props.location.hash.split("#")[1].split("&").reduce(function(result, param) {
        var [key, value] = param.split("=");
        result[key] = value;
        return result;
      }, {});
      var myHour = new Date();
    myHour.setHours(myHour.getHours() + 12); //one hour from now
      localStorage.setItem('accessTokenWebex', hashParams.access_token);
      localStorage.setItem('accessTokenWebexRemovalDate', myHour);

      props.history.push("/advisorRoom");
  return (
    <div className="AdvisorWebexRedirect">
        Webex redirect loading
    </div>
  );
}

export default AdvisorWebexRedirect;
