import React, { useState, useEffect, useRef } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true
};

export default function PdfViewerCustomer(props) {
  const file = props.file;

  let layerOverPDFViewer = useRef(null);
  let pdfViewerHTML = useRef(null);
  let pdfViewer = useRef(null);
  let pdfViewerDiv = useRef(null);

  let myPdfViewerContainer = useRef(null);
  var eventBus = new window.pdfjsViewer.EventBus();

  let pdfZoom = useRef(1.0);

  useEffect(() => {
    if ((pdfViewer.current == null) & (pdfViewerHTML.current != null)) {
      pdfViewer.current = new window.pdfjsViewer.PDFSinglePageViewer({
        container: pdfViewerHTML.current,
        eventBus: eventBus,
        textLayerFactory: new window.pdfjsViewer.DefaultTextLayerFactory()
      });

      // layerOverPDFViewer.current.style.display = "block";
      // layerOverPDFViewer.current.style.height =
      //   pdfViewerHTML.current.clientHeight + "px";
      // layerOverPDFViewer.current.style.width =
      //   pdfViewerHTML.current.clientWidth + "px";
      // layerOverPDFViewer.current.style.top =
      //   pdfViewerHTML.current.offsetTop + "px";
      // layerOverPDFViewer.current.style.left =
      //   pdfViewerHTML.current.offsetLeft + "px";

      // pdfViewerHTML.current.addEventListener('scroll', function(e) {
      // 	console.log(pdfViewerHTML.current.scrollTop);

      // 	props.actionPdfScroll(
      // 		{
      // 			scrollTop: pdfViewerHTML.current.scrollTop,
      // 			scrollLeft: pdfViewerHTML.current.scrollLeft
      // 		});

      // })

      eventBus.on("pagesinit", function () {
        pdfViewer.current.currentScaleValue = "page-width";
        pdfViewer.current.currentPageNumber = props.pageNumber;

        //pdfViewer.currentScale = pdfViewerHTML.clientWidth / pdfViewer.container.scrollWidth;
      });

      let fileReader = new FileReader();

      fileReader.onload = function () {
        let typedarray = new Uint8Array(this.result);

        //replaced the old function with the new api
        const loadingTask = window.pdfjsLib.getDocument(typedarray);
        loadingTask.promise.then((pdfDocument) => {
          pdfViewer.current.setDocument(pdfDocument);
        });
      };
      fileReader.readAsArrayBuffer(file);
    }

    // pdfViewer.current.setDocument(file);
  }, [pdfViewerHTML.current]);

  window.onclick = function (event) {
    console.log(event);
    if (event.target === document.getElementById("myPdfViewerContainer")) {
      props.actionMinimizePdf();
    }
  };

  // let pdfCurrentPageNum = useRef(1);

  // function onPrevPage() {
  // 	if (pdfCurrentPageNum.current <= 1) {
  // 		return;
  // 	}
  // 	pdfCurrentPageNum.current--;
  // 	pdfViewer.current.currentPageNumber = pdfCurrentPageNum.current;
  // 	pdfViewer.current.currentScaleValue = "page-width";
  // 	props.actionPageChange(pdfCurrentPageNum.current);
  // }

  // function onNextPage() {
  // 	if (pdfCurrentPageNum.current >= pdfViewer.current.pagesCount) {
  // 		return;
  // 	}
  // 	pdfCurrentPageNum.current++;
  // 	pdfViewer.current.currentPageNumber = pdfCurrentPageNum.current;
  // 	props.actionPageChange(pdfCurrentPageNum.current);
  // 	pdfViewer.current.currentScaleValue = "page-width";
  // }

  console.log("props.PageNumber", props.pageNumber);
  console.log("props.scrollLeft", props.scrollLeft);
  console.log("props.scrollTop", props.scrollTop);

  if (pdfViewer.current != null) {
    pdfViewer.current.currentPageNumber = props.pageNumber;
    pdfViewer.current.currentScaleValue = "page-width";
  }

  if (
    (pdfViewerHTML.current != null) &
    (props.scrollTop != null) &
    (props.scrollLeft != null)
  ) {
    const localHeight = pdfViewerHTML.current.scrollHeight;
    const localWidth = pdfViewerHTML.current.scrollWidth;

    pdfViewerHTML.current.scrollTo({
      top: (props.scrollTop * localHeight) / props.scrollHeight + 10,
      left: (props.scrollLeft * localWidth) / props.scrollWidth + 10
    });
  }

  if (pdfViewerDiv.current != null) {
    setTransformOrigin();
    pdfViewerDiv.current.style.transform =
      "scale(" + props.pdfZoom + ") rotate(" + props.pdfRotate + "deg)";
    setTransformOrigin();
  }

  function setTransformOrigin() {
    if (props.pdfRotate === 0) {
      pdfViewerDiv.current.style.transformOrigin = "0% 0% 0px";
    } else {
      pdfViewerDiv.current.style.transformOrigin = "center center";
    }
  }

  // if (layerOverPDFViewer.current != null && props.canMakeSelection == false) {
  //   layerOverPDFViewer.current.style.display = "block";
  //   layerOverPDFViewer.current.style.height =
  //     pdfViewerHTML.current.clientHeight + "px";
  //   layerOverPDFViewer.current.style.width =
  //     pdfViewerHTML.current.clientWidth + "px";
  //   layerOverPDFViewer.current.style.top =
  //     pdfViewerHTML.current.offsetTop + "px";
  //   layerOverPDFViewer.current.style.left =
  //     pdfViewerHTML.current.offsetLeft + "px";
  // }

  // if (layerOverPDFViewer.current != null && props.canMakeSelection == true) {
  //   layerOverPDFViewer.current.style.display = "none";
  // }

  return (
    <div id="myPdfViewerContainer" className="" data-html2canvas-ignore>
      {/* <div>
        <div className="">
          <button onClick={(e) => props.actionMinimizePdf()}>
            Minimize Pdf
          </button>
          <br/>
          <button onClick={(e) => zoomInAction()}>
            Zoom In
          </button>
          <button onClick={(e) => zoomOutAction()}>
            Zoom Out
          </button>
        </div>
      </div> */}

      <div>
        <div className="pdfWrapperButtons" style={{ display: "flex" }}>
          {/* <span
            className="ui-component-toggle--label"
            style={{ color: "whitesmoke" }}
          >
            Text Selection
          </span> */}
          {/* {props.isAdvisorSelection == false ? (<button onClick={(e) => props.setWhoCanSelect(true)}>
            Set advisor to select
          </button>) : (          <button onClick={(e) => props.setWhoCanSelect(false)}>
            Set customer to select
          </button>)} */}
          <Row style={{ margin: "auto" }}>
            <Col xl="12" className="text-center">
              <div className="pdfFileNameText">
                PDF File shared by the advisor
              </div>
            </Col>
          </Row>

          {/* <button onClick={(e) => props.actionMinimizePdf()}>
            Minimize Pdf
          </button> */}

          {/* <div className="ui-component-toggle ui-layout-flex" style={{margin: "0"}}>
					<label htmlFor="highlight__you" className="ui-component-toggle--label">You</label>
					<label htmlFor="highlight__peer" className="ui-component-toggle--label" style={{cursor: "default"}}>Peer</label>
			</div> */}
        </div>
      </div>
      <div
        id="viewerContainer"
        className="pdfContainer pdfContainerCustomer"
        ref={pdfViewerHTML}
      >
        <div id="viewer" className="pdfViewer" ref={pdfViewerDiv}></div>
        <div
          id="layerOver"
          style={{ display: "none" }}
          ref={layerOverPDFViewer}
        ></div>
      </div>
    </div>
  );
}
