import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Webex from "webex";
import SockJsClient from "react-stomp";
import chatImgClosed from "./../../assets/chatClosed.png";
import chatImgOpened from "./../../assets/chatOpened.png";
import shareClosed from "./../../assets/shareClosed.png";
import shareOpened from "./../../assets/shareOpened.png";
import warningImg from "./../../assets/warning.png";
import micOpen from "./../../assets/micOpen.png";
import micClosed from "./../../assets/micClosed.png";
import closeCall from "./../../assets/closeCall.png";

import videoOpen from "./../../assets/videoOpen.png";
import videoClosed from "./../../assets/videoClosed.png";
import unicreditLogo from "./../../assets/unicreditLogo.png";

import ModalShareScreen from "./../../components/modals/ModalShareScreen";
import ModalCloseCall from "./../../components/modals/ModalCloseCall";
import ModalDisconnected from "./../../components/modals/ModalDisconnected";

// import Sample from "./../Sample";
import PdfViewerCustomer from "../../components/pdfViewer/pdfViewerCustomer";
import adapter from "webrtc-adapter";
import Chat from "../../components/chat/chat";
import pdfIconImg from "./../../assets/pdfIcon.png";
import stoppedVideoImg from "./../../assets/stoppedVideoImg.png";
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Tooltip,
  UncontrolledTooltip
} from "reactstrap";

const SOCKET_URL = window.__RUNTIME_CONFIG__.SOCKET_URL_ADVISOR;
const TURN_URL = window.__RUNTIME_CONFIG__.TURN_SERVER_CUSTOMER_URL;
const TURN_USER = window.__RUNTIME_CONFIG__.TURN_SERVER_USER;
const TURN_PASSWORD = window.__RUNTIME_CONFIG__.TURN_SERVER_PASSWORD;
const BASE_URL = window.__RUNTIME_CONFIG__.BASE_URL;
const IS_WEBEX = window.__RUNTIME_CONFIG__.IS_WEBEX === "true";

function Client(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAdvisorConnected, setIsAdvisorConnected] = useState(false);
  const [socketTopic, setSocketTopic] = useState("");
  const [meetingQueueName, setMeetingQueueName] = useState("");
  const [audioState, setAudioState] = useState("audio on");
  const [videoState, setVideoState] = useState("video on");
  const [myMeeting, setMyMeeting] = useState(null);
  const [filePDF, setFilePDF] = useState();
  const [canMakeSelection, setCanMakeSelection] = useState(false);
  let canMakeSelectionIntern = useRef(false);
  const [R4AToken, setR4AToken] = useState("");
  const [message, setMessage] = useState("You server message here.");
  const [socketClient, setSocketClient] = useState(null);
  const [webexSDK, setWebexSDK] = useState(null);
  const [webexToken, setWebexToken] = useState("");
  const [webexRoomId, setWebexRoomId] = useState("");
  const [problemData, setProblemData] = useState(null);
  const [webexError, setWebexError] = useState(false);
  const [shareScreenStatus, setShareScreenStatus] = useState("NOT OPEN");
  const [minifiedPdf, setMinifiedPdf] = useState(false);
  const [shareScreenState, setShareScreenState] = useState(false);
  const [pdfZoom, setPdfZoom] = useState(1);
  const [pdfRotate, setPdfRotate] = useState(0);
  const [loadingPdfSpinner, setLoadingPdfSpinner] = useState(false);

  const [chatMessages, setChatMessages] = useState([]);
  const [micState, setMicState] = useState(true);
  const [videoStateBool, setVideoStateBool] = useState(true);
  let videoStateBoolLocal = useRef(true);
  const [modalShareScreen, setModalShareScreen] = useState(false);
  const [modalCloseCall, setModalCloseCall] = useState(false);
  const [modalDisconnected, setModalDisconnected] = useState(false);

  const [isMouse, setIsMouse] = useState(false);

  let remoteVideo = useRef();
  let remoteVideoSmall = useRef();

  let myVideo = useRef();
  const filePdfState = useRef(false);

  let modalDisconnectStatus = useRef(false);

  let remoteShareStream = useRef();

  const r4aID = localStorage.getItem("r4aIDCustomer");

  const [customerName, setCustomerName] = useState("");

  const videoGrid = useRef();

  let chatMessageWidget = useRef();
  let chatIcon = useRef();
  let advisorMouseRef = useRef();

  let remoteVideoImg = useRef();
  let remoteVideoImgSmall = useRef();

  let myVideoImg = useRef();

  let smallVideosWrapper = useRef(null);

  const [pdfPageNumber, setPdfPageNumer] = useState(1);
  const [pdfScrollTop, setPdfScrollTop] = useState(null);
  const [pdfScrollLeft, setPdfScrollLeft] = useState(null);
  const [pdfScrollHeight, setPdfScrollHeight] = useState(null);
  const [pdfScrollWidth, setPdfScrollWidth] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://code.s4d.io/widget-space/production/bundle.js";
    script.async = false;

    document.body.appendChild(script);

    setIsLoaded(true);

    axios
      .get(BASE_URL + "customer/meeting/" + r4aID + "/wsinfo", {
        headers: { RemoteMeetingId: r4aID }
      })
      .then((res) => {
        const { meetingTopicName, meetingQueueName } = res.data;
        setCustomerName(res.data.customerName);
        setMeetingQueueName(meetingQueueName);
        setSocketTopic(meetingTopicName);
        setR4AToken(res.headers["r4asessionid"]);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          if (error.response.status == 403) {
            setProblemData(error.response.data);
          }
        } else if (error.request) {
          setProblemData("Service is unavailable!");
        }
      });
    //});
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    //window.addEventListener("unload", handleTabClosing);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      //window.removeEventListener("unload", handleTabClosing);
    };
  });

  // const handleTabClosing = () => {

  // };

  const alertUser = (event) => {
    if (modalDisconnectStatus.current == false) {
      sendMessage("CUSTOMER_DISCONNECT_TO_WS", {});
    }
  };

  const peerRef = useRef();
  const receiveChannel = useRef();
  const receiveChannelPDF = useRef();
  const receiveChannelMouse = useRef();
  const receiveChannelEvent = useRef();

  const lastTextSelection = useRef({});

  let isMessageBoxOpened = useRef(false);

  const SEC_30_S = 20000;

  useEffect(() => {
    const interval = setInterval(() => {
      sendMessage("CUSTOMER_HEART_BEAT_TO_WS", {});
    }, SEC_30_S);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [socketClient]);

  const [chatState, setChatState] = useState(false);

  function openChat() {
    if (chatMessageWidget.current != null) {
      smallVideosWrapper.current.style.display = "none";
      chatMessageWidget.current.style.display = "block";
      setChatState(true);
      if (!isMessageBoxOpened.current & IS_WEBEX) {
        let close = document.createElement("span");
        close.classList.add("close");
        close.style.padding = "0px 12px 0px 0px";
        close.appendChild(document.createTextNode("x"));
        chatMessageWidget.current
          .querySelector(".webex-title-bar")
          .appendChild(close);
        close.addEventListener("click", (e) => {
          chatIcon.current.style.display = "block";
          setChatState(false);
        });
      }
      setChatMessages(
        chatMessages.map((message) => {
          return {
            ...message,
            seen: true
          };
        })
      );
      isMessageBoxOpened.current = true;
    }
  }

  const sendChannel = useRef();
  const sendEventChannel = useRef();

  function handleReceiveMessage(event) {
    console.log("received on WEBRTC", event.data);
  }

  function handleReceiveChannelStatusChange(event) {
    if (receiveChannel.current) {
      console.log(
        "Receive channel's status has changed to " +
          receiveChannel.current.readyState
      );
    }
  }

  function createPeer(sdpX) {
    const peer = new RTCPeerConnection({
      iceServers: [
        {
          url: TURN_URL + "?transport=tcp",
          credential: TURN_PASSWORD,
          username: TURN_USER
        },
        {
          url: TURN_URL + "?transport=udp",
          credential: TURN_PASSWORD,
          username: TURN_USER
        }
      ],
      iceTransportPolicy: 'relay'
    });

    if (!IS_WEBEX) {
      peer.ontrack = (e) => {
        console.log("gotRemoteStream", e.track, e.streams[0]);
        console.log("ONTRACK", e);
        addVideoStream(remoteVideo.current, e.streams[0]);
        addVideoStream(remoteVideoSmall.current, e.streams[0]);
      };
    }
    peer
      .setRemoteDescription(sdpX)
      .then(async function (e) {
        if (!IS_WEBEX) {
          const constraints = {
            audio: true,
            video: true
          };
          let stream = null;

          try {
            stream = await navigator.mediaDevices.getUserMedia(constraints);
            /* use the stream */
            console.log("STREAM", stream);
            addVideoStream(myVideo.current, stream);
            stream.getTracks().forEach((track) => peer.addTrack(track, stream));
          } catch (err) {
            /* handle the error */
            console.warn(err);
          }
        }
        const offerOptions = {
          offerToReceiveAudio: 1,
          offerToReceiveVideo: 1
        };
        return peer.createAnswer(offerOptions);
      })
      .then((sdp) => {
        peer.setLocalDescription(sdp);
        return sdp;
      })
      .then((sdp) => {
        console.log("ANSWER SENT");
        sendMessage("CUSTOMER_ANSWER_TO_WS", { sdp: sdp });
      });

    const dataChannelOptions = {
      ordered: true,
      maxPacketLifeTime: 3000 // in milliseconds
    };

    sendChannel.current = peer.createDataChannel(
      "sendContent",
      dataChannelOptions
    );
    sendChannel.current.onmessage = handleReceiveMessage;

    sendEventChannel.current = peer.createDataChannel(
      "sendEventContent",
      dataChannelOptions
    );
    sendEventChannel.current.onmessage = handleReceiveMessage;

    peer.ondatachannel = receiveChannelCallback;
    peer.onicecandidate = handleICECandidateEvent;
    peer.onconnectionstatechange = (e) => onStateChange(peer, e);
    peer.oniceconnectionstatechange = (e) => onIceStateChange(peer, e);

    return peer;
  }

  function receiveChannelCallback(event) {
    if (event.channel.label === "sendPdfContent") {
      receiveChannelPDF.current = event.channel;
      receiveChannelPDF.current.onmessage = handleReceivePdfMessage;
      receiveChannelPDF.current.onopen = handleReceiveChannelStatusChange;
      receiveChannelPDF.current.onclose = handleReceiveChannelStatusChange;
    } else if (event.channel.label === "sendContent") {
      receiveChannel.current = event.channel;
      receiveChannel.current.onmessage = handleReceiveMessage;
      receiveChannel.current.onopen = handleReceiveChannelStatusChange;
      receiveChannel.current.onclose = handleReceiveChannelStatusChange;
    } else if (event.channel.label === "sendMouseContent") {
      receiveChannelMouse.current = event.channel;
      receiveChannelMouse.current.onmessage = handleReceiveMouseMessage;
      receiveChannelMouse.current.onopen = handleReceiveChannelStatusChange;
      receiveChannelMouse.current.onclose = handleReceiveChannelStatusChange;
    } else if (event.channel.label === "sendEventContent") {
      receiveChannelEvent.current = event.channel;
      receiveChannelEvent.current.onmessage = handleReceiveEventMessage;
      receiveChannelEvent.current.onopen = handleReceiveChannelStatusChange;
      receiveChannelEvent.current.onclose = handleReceiveChannelStatusChange;
    }
  }

  function selectNodes(nodes, from, to) {
    let range, selection;
    if (window.getSelection && document.createRange) {
      range = document.createRange();
      if (nodes.length === 1) {
        range.setStart(nodes[0].firstChild, from);
        range.setEnd(nodes[0].firstChild, to);
      } else {
        range = document.createRange();
        range.setStart(nodes[0].firstChild, from);
        range.setEnd(nodes[1].firstChild, to);
      }
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (document.selection && document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(nodes);
      range.select();
    }
  }

  function actionMinimizePdf() {
    changeStylePdfClose();
    setMinifiedPdf(true);
  }

  function handleReceiveEventMessage(event) {
    const message = JSON.parse(event.data);
    const eventType = message.event;
    const data = message.msg;
    // console.log("event.data", event.data);
    // console.log("data", data);
    // console.log("eventType", eventType);
    if (eventType === "PAGE_CHANGE") {
      setPdfPageNumer(data);
    } else if (eventType === "ZOOM_PDF") {
      setPdfZoom(data);
    } else if (eventType === "ROTATE_PDF") {
      setPdfRotate(data);
    } else if (eventType === "PAGE_SCROLL") {
      setPdfScrollTop(data.scrollTop);
      setPdfScrollLeft(data.scrollLeft);
      setPdfScrollHeight(data.scrollHeight);
      setPdfScrollWidth(data.scrollWidth);
    } else if (eventType === "CLOSE_PDF") {
      if (remoteVideo.current && remoteVideoSmall.current) {
        remoteVideo.current.srcObject = remoteVideoSmall.current.srcObject;
        remoteVideo.current.style.display = "block";
      }
      setFilePDF(null);
      filePdfState.current = false;
      // changeStylePdfClose();
      setMinifiedPdf(false);
      remoteVideoSmall.current.style.display = "none";
    } else if (eventType === "ADVISOR_SELECT") {
      canMakeSelectionIntern.current = false;
      setCanMakeSelection(false);
    } else if (eventType === "CUSTOMER_SELECT") {
      canMakeSelectionIntern.current = true;
      setCanMakeSelection(true);
    } else if (eventType === "STOP_VIDEO") {
      if (filePdfState.current == false) {
        remoteVideo.current.style.display = "none";
        remoteVideoImg.current.style.display = null;
      } else {
        remoteVideoSmall.current.style.display = "none";
        remoteVideoImgSmall.current.style.display = null;
      }
    } else if (eventType === "START_VIDEO") {
      if (filePdfState.current == false) {
        remoteVideo.current.style.display = null;
        remoteVideoImg.current.style.display = "none";
      } else {
        remoteVideoSmall.current.style.display = null;
        remoteVideoImgSmall.current.style.display = "none";
      }
    } else if (eventType === "TEXT_SELECT") {
      lastTextSelection.current = data;
      try {
        let textLayerContainer = document.getElementsByClassName("textLayer");
        if (textLayerContainer) {
          if (textLayerContainer[0] && textLayerContainer[0].children) {
            let elem = Array.from(textLayerContainer[0].children).filter(
              (t) =>
                t.innerHTML === data.fromNodeValue ||
                t.innerHTML === data.toNodeValue
            );

            selectNodes(elem, data.from, data.to);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else if (eventType === "START_MOUSE") {
      setIsMouse(true);
    } else if (eventType === "STOP_MOUSE") {
      setIsMouse(false);
    }
  }

  function handleReceiveMouseMessage(event) {
    if (advisorMouseRef.current != null) {
      const message = event.data;
      const coords = message.split(";");
      advisorMouseRef.current.style.left =
        (coords[0] * window.innerWidth) / coords[2] + "px";
      advisorMouseRef.current.style.top =
        (coords[1] * window.innerHeight) / coords[3] + "px";
    }
  }

  let receivePdfBuffer = useRef([]);

  function changeStylePdfClose() {}

  function changeStylePdfOpen() {
    videoGrid.current.classList.add("videoGridWithPdf");
    document
      .querySelectorAll("video")
      .forEach((e) => e.classList.remove("remoteVideoClass"));
    document
      .querySelectorAll("video")
      .forEach((e) => e.classList.remove("fullScreenVideo"));
  }

  function handleReceivePdfMessage(event) {
    const message = event.data;

    if (message === "End") {
      setLoadingPdfSpinner(false);

      remoteVideoSmall.current.style.display = "block";
      remoteVideo.current.style.display = "none";
      canMakeSelectionIntern.current = false;
      const received = new Blob(receivePdfBuffer.current);
      receivePdfBuffer.current = [];

      const url = URL.createObjectURL(received);
      setFilePDF(received);
      filePdfState.current = true;

      // changeStylePdfOpen();
      setMinifiedPdf(false);
    } else {
      receivePdfBuffer.current.push(message);
      setLoadingPdfSpinner(true);
    }
  }

  function onStateChange(pc, event) {
    if (pc) {
      // console.log(`State: ${pc.connectionState}`);
      console.log("State: ", pc.connectionState);
      console.log("State change event: ", event);
      if (pc.connectionState === "failed") {
        pc.restartIce();
      }
    }
  }

  function onIceStateChange(pc, event) {
    if (pc) {
      // console.log(`ICE state: ${pc.iceconnectionState}`);
      console.log("ICE state: ", pc.iceconnectionState);
      console.log("ICE state change event: ", event);
      if (pc.iceconnectionState === "failed") {
        pc.restartIce();
      }
    }
  }

  function handleICECandidateEvent(e) {
    console.log(e);
    if (e.candidate) {
      var candidate = e.candidate.candidate;
      // Relay candidate found
      sendMessage("CUSTOMER_ICE_CANDIDATE_TO_WS", {
        iceCandidate: e.candidate
      });
    }
  }

  function handleNewICECandidateMsg(incoming) {
    if (peerRef.current != null) {
      const candidate = new RTCIceCandidate(incoming);

      peerRef.current.addIceCandidate(candidate).catch((e) => console.log(e));
    }
  }

  let onConnected = () => {
    console.log("Connected!!");
  };

  useEffect(() => {
    if (IS_WEBEX) {
      if (webexToken) {
        const webexSDKLocal = Webex.init();

        console.log("Webex initialization...");
        console.log("Webex token, " + webexToken);
        webexSDKLocal.once(`ready`, function () {
          console.log("Registering to Webex ...");
          webexSDKLocal.authorization
            .requestAccessTokenFromJwt({ jwt: webexToken })
            .then(() => {
              webexSDKLocal.meetings
                .register()
                .then(() => {
                  webexSDKLocal.people.get("me").then((me) => {
                    let webexId = me.id;
                    setWebexSDK(webexSDKLocal);
                    sendMessage("CUSTOMER_ADD_TO_MEETING_TO_WS", {
                      customerWebexId: webexId
                    });
                  });
                })
                .catch((err) => {
                  console.error(err);
                  alert(err);
                  throw err;
                });
            });
        });
      }
    }
  }, [webexToken]);

  function addVideoStream(video, stream) {
    video.srcObject = stream;
    video.addEventListener("click", function (e) {
      var elem = document.querySelector(".fullScreenVideo");
      if (elem != undefined) {
        document
          .querySelectorAll("video")
          .forEach((e) => e.classList.remove("fullScreenVideo"));
        remoteVideo.current.classList.add("remoteVideoClass");
      }
      if (elem != e.target) {
        e.target.classList.add("fullScreenVideo");
        remoteVideo.current.classList.remove("remoteVideoClass");
      }
    });
    video.addEventListener("loadedmetadata", () => {
      video.play();
    });
    // videoGrid.current.append(video);
  }

  function bindMeetingEvents(meeting) {
    meeting.on("error", (err) => {
      console.error(err);
    });

    meeting.on("meeting:startedSharingRemote", () => {
      // Set the source of the video element to the previously stored stream
      // document.getElementById('remote-screen').srcObject = remoteShareStream.current;
      // setShareScreenStatus("OPEN");
    });

    meeting.on("meeting:stoppedSharingRemote", () => {
      // document.getElementById('remote-screen').srcObject = null;
      // setShareScreenStatus("NOT OPEN");
    });

    // Handle media streams changes to ready state
    meeting.on("media:ready", (media) => {
      if (!media) {
        return;
      }

      if (media.type === "local") {
        addVideoStream(myVideo.current, media.stream);
      }
      if (media.type === "remoteVideo") {
        remoteVideo.current = document.createElement("video");
        remoteVideo.current.classList.add("remoteVideoClass");
        addVideoStream(remoteVideo.current, media.stream);
      }
      if (media.type === "remoteAudio") {
        let remoteAudio = document.createElement("audio");
        remoteAudio.setAttribute("autoplay", "");
        remoteAudio.setAttribute("playsInLine", "");
        remoteAudio.srcObject = media.stream;
        videoGrid.current.append(remoteAudio);
      }
      if (media.type === "remoteShare") {
        // Remote share streams become active immediately on join, even if nothing is being shared
        remoteShareStream.current = media.stream;
        remoteShareStream.current.onended = function (event) {
          if (myMeeting != null) {
            setShareScreenStatus("NOT OPEN");
            // First check if we can update, if not, wait and retry
            waitForMediaReady(myMeeting).then(() => {
              myMeeting.stopShare();
            });
          }
        };
      }
      // if (media.type === 'localShare') {
      //   document.getElementById('self-screen').srcObject = media.stream;
      // }
    });

    // Handle media streams stopping
    meeting.on("media:stopped", (media) => {
      // Remove media streams
      if (media.type === "local") {
        myVideo.current.remove();
      }
      if (media.type === "remoteVideo") {
        document.getElementById("remote-view-video").srcObject = null;
      }
      if (media.type === "remoteAudio") {
        document.getElementById("remote-view-audio").srcObject = null;
      }
      if (media.type === "remoteShare") {
        // Remote share streams become active immediately on join, even if nothing is being shared
        remoteShareStream.current = media.stream;
      }
      // if (media.type === 'localShare') {
      //   document.getElementById('self-screen').srcObject = null;
      // }
    });
  }

  function waitForMediaReady(meeting) {
    return new Promise((resolve, reject) => {
      if (meeting.canUpdateMedia()) {
        resolve();
      } else {
        console.info(
          "SHARE-SCREEN: Unable to update media, pausing to retry..."
        );
        let retryAttempts = 0;

        const retryInterval = setInterval(() => {
          retryAttempts += 1;
          console.info("SHARE-SCREEN: Retry update media check");

          if (meeting.canUpdateMedia()) {
            console.info("SHARE-SCREEN: Able to update media, continuing");
            clearInterval(retryInterval);
            resolve();
          }
          // If we can't update our media after 15 seconds, something went wrong
          else if (retryAttempts > 15) {
            console.error(
              "SHARE-SCREEN: Unable to share screen, media was not able to update."
            );
            clearInterval(retryInterval);
            reject();
          }
        }, 10000);
      }
    });
  }

  function joinMeeting(meeting) {
    return meeting.join().then(() => {
      const mediaSettings = {
        receiveVideo: true,
        receiveAudio: true,
        receiveShare: true,
        sendVideo: true,
        sendAudio: true,
        sendShare: false
      };

      // Get our local media stream and add it to the meeting
      return meeting.getMediaStreams(mediaSettings).then((mediaStreams) => {
        const [localStream, localShare] = mediaStreams;

        meeting.addMedia({
          localShare,
          localStream,
          mediaSettings
        });
      });
    });
  }

  function handleRecieveCall(sdpX) {
    peerRef.current = createPeer(sdpX);
  }

  let onMessageReceived = (msg) => {
    setMessage(msg);
    console.log(msg);
    //const jsonMsg = JSON.parse(msg);
    if (msg.eventType === "WS_ADVISOR_CLOSE_SESSION_TO_CUSTOMER") {
      myVideo.current.srcObject.getTracks().forEach((t) => {
        t.stop();
      });
      myVideo.current.srcObject = null;
      peerRef.current.close();
      peerRef.current = null;
      setProblemData("The advisor has closed the session");
    } else if (msg.eventType === "WS_ADVISOR_CONNECTED_TO_CUSTOMER") {
      setIsAdvisorConnected(true);
    } else if (msg.eventType === "WS_WEBEX_ROOM_AND_GUEST_TOKEN_TO_CUSTOMER") {
      if (IS_WEBEX) {
        setWebexToken(msg.message.webexGuestToken);
        setWebexRoomId(msg.message.webexRoomId);
      }
    } else if (msg.eventType === "WS_ADVISOR_DISCONNECTED_TO_CUSTOMER") {
      if (isAdvisorConnected) {
        // alert("Advisor has disconnected. You will be redirected to landing page");
        setModalDisconnected(true);
        // props.history.go(0);
      }
    } else if (msg.eventType === "WS_CUSTOMER_ADDED_TO_MEETING_TO_CUSTOMER") {
      if (IS_WEBEX) {
        webexSDK.meetings
          .create(webexRoomId)
          .then((meeting) => {
            bindMeetingEvents(meeting);
            joinMeeting(meeting);
            setMyMeeting(meeting);
          })
          .catch((error) => {
            console.error(error);
          });
        window.webex.widget(chatMessageWidget.current).spaceWidget(
          Object.assign(
            { guestToken: webexToken },
            {
              destinationId: webexRoomId,
              destinationType: "spaceId",
              spaceActivities: {
                files: false,
                meet: false,
                message: true,
                people: false
              },
              initialActivity: "message"
            }
          )
        );
      }
    } else if (msg.eventType === "WS_ADVISOR_ICE_CANDIDATE_TO_CUSTOMER") {
      handleNewICECandidateMsg(msg.message.iceCandidate);
    } else if (msg.eventType === "WS_OFFER_TO_CUSTOMER") {
      console.log("OFFER RECEIVED");
      handleRecieveCall(msg.message.sdp);
    } else if (msg.eventType == "WS_MEETING_CLOSED_TO_ALL") {
      props.history.go(0);
    } else if (msg.eventType == "WS_NO_GUEST_TOKEN_TO_ALL") {
      if (IS_WEBEX) {
        setWebexError(true);
      }
    } else if (msg.eventType == "WS_SEND_CHAT_MESSAGE_TO_ALL") {
      setChatMessages([
        ...chatMessages,
        {
          msg: msg.message.message,
          sentBy: msg.message.sentBy,
          seen: chatState
        }
      ]);
    }
  };

  let sendMessage = (event, msg) => {
    const payloadToSend = JSON.stringify({ message: msg, eventType: event });
    console.log("SEND TO SERVER SOCKET", payloadToSend);
    try {
      socketClient.sendMessage(meetingQueueName, payloadToSend);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  function stopSendAudio() {
    setMicState(false);
    if (!IS_WEBEX & (myVideo.current != null)) {
      myVideo.current.srcObject.getTracks().forEach((t) => {
        if (t.kind == "audio") {
          t.enabled = false;
        }
      });
    }
    if (myMeeting) {
      myMeeting.muteAudio().then(() => {
        setAudioState("audio off");
      });
    }
  }

  function startSendAudio() {
    setMicState(true);

    if (!IS_WEBEX & (myVideo.current != null)) {
      myVideo.current.srcObject.getTracks().forEach((t) => {
        if (t.kind == "audio") {
          t.enabled = true;
        }
      });
    }
    if (myMeeting) {
      myMeeting.unmuteAudio().then(() => {
        setAudioState("audio on");
      });
    }
  }

  function stopSendVideo() {
    setVideoStateBool(false);
    videoStateBoolLocal.current = false;
    if (shareScreenState != true) {
      if (!IS_WEBEX & (myVideo.current != null)) {
        myVideo.current.srcObject.getTracks().forEach((t) => {
          if (t.kind == "video") {
            t.enabled = false;
            sendEventChannel.current.send(
              JSON.stringify({
                event: "STOP_VIDEO",
                msg: {}
              })
            );
            myVideo.current.style.display = "none";
            myVideoImg.current.style.display = null;
          }
        });
      }
      if (myMeeting) {
        myMeeting.muteVideo().then(() => {
          setVideoState("video off");
        });
      }
    }
  }

  function closeChat() {
    chatMessageWidget.current.style.display = "none";
    // chatIcon.current.style.display = "block";
    setChatState(false);
    smallVideosWrapper.current.style.display = "block";
    setChatMessages(
      chatMessages.map((message) => {
        return {
          ...message,
          seen: true
        };
      })
    );
  }

  function startSendVideo() {
    setVideoStateBool(true);
    videoStateBoolLocal.current = true;
    if (shareScreenState != true) {
      if (!IS_WEBEX & (myVideo.current != null)) {
        myVideo.current.srcObject.getTracks().forEach((t) => {
          if (t.kind == "video") {
            t.enabled = true;
            sendEventChannel.current.send(
              JSON.stringify({
                event: "START_VIDEO",
                msg: {}
              })
            );
            myVideo.current.style.display = null;
            myVideoImg.current.style.display = "none";
          }
        });
      }
      if (myMeeting) {
        myMeeting.unmuteVideo().then(() => {
          setVideoState("video on");
        });
      }
    }
  }
  function sendRTCMSG() {
    sendChannel.current.send("TEST MSG FROM CLIENT");
    console.log("AM TRIMIS WEBRTC MSG");
  }

  function handleSelectionChange() {
    let selection = document.getSelection();
    if (canMakeSelectionIntern.current) {
      try {
        sendEventChannel.current.send(
          JSON.stringify({
            event: "TEXT_SELECT",
            msg: {
              fromNodeValue: selection.anchorNode.wholeText,
              toNodeValue: selection.focusNode.wholeText,
              from: selection.anchorOffset,
              to: selection.focusOffset
            }
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function startShareScreen() {
    if (!IS_WEBEX & (myVideo.current != null)) {
      var displayMediaOptions = {
        video: {
          cursor: "always"
        },
        audio: false
      };
      try {
        setShareScreenState(true);

        const stream = await navigator.mediaDevices.getDisplayMedia(
          displayMediaOptions
        );
        console.log(stream.getTracks());
        let videoTrack = stream.getVideoTracks()[0];

        stream.getVideoTracks()[0].onended = function () {
          stopShareScreen();
        };

        stream.onended = () => {
          stopShareScreen();
        };
        if (videoStateBoolLocal.current == false) {
          sendEventChannel.current.send(
            JSON.stringify({
              event: "START_VIDEO",
              msg: {}
            })
          );
          myVideo.current.style.display = null;
          myVideoImg.current.style.display = "none";
        }

        var sender = peerRef.current.getSenders().find(function (s) {
          return s.track.kind == videoTrack.kind;
        });
        sender.replaceTrack(videoTrack);

        myVideo.current.srcObject = stream;
        setShareScreenStatus("OPEN");
        sendEventChannel.current.send(
          JSON.stringify({
            event: "START_VIDEO",
            msg: {}
          })
        );
        myVideo.current.style.display = null;
        myVideoImg.current.style.display = "none";
      } catch (err) {
        console.error("Error: " + err);
      }
    }
    if (myMeeting != null) {
      setShareScreenStatus("OPEN");
      // First check if we can update
      waitForMediaReady(myMeeting).then(() => {
        console.info("SHARE-SCREEN: Sharing screen via `shareScreen()`");
        myMeeting
          .shareScreen()
          .then(() => {
            console.info("SHARE-SCREEN: Screen successfully added to meeting.");
          })
          .catch((e) => {
            console.error("SHARE-SCREEN: Unable to share screen, error:");
            console.error(e);
          });
      });
    } else {
      console.error("No active meeting available to share screen.");
    }
  }

  async function stopShareScreen() {
    if (!IS_WEBEX & (myVideo.current != null)) {
      setShareScreenState(false);

      myVideo.current.srcObject.getTracks().forEach((t) => {
        if (t.kind == "video") {
          t.enabled = false;
          t.stop();
        }
      });
      const constraints = {
        audio: true,
        video: true
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      let videoTrack = stream.getVideoTracks()[0];
      myVideo.current.srcObject = stream;

      var sender = peerRef.current.getSenders().find(function (s) {
        return s.track.kind == videoTrack.kind;
      });
      sender.replaceTrack(videoTrack);
      setShareScreenStatus("NOT OPEN");

      if (videoStateBoolLocal.current == true) {
        myVideo.current.style.display = "block";
        myVideoImg.current.style.display = "none";
      } else {
        myVideo.current.style.display = "none";
        myVideoImg.current.style.display = "block";
        sendEventChannel.current.send(
          JSON.stringify({
            event: "STOP_VIDEO",
            msg: {}
          })
        );
      }
    }
    if (myMeeting != null) {
      setShareScreenStatus("NOT OPEN");
      // First check if we can update, if not, wait and retry
      waitForMediaReady(myMeeting).then(() => {
        myMeeting.stopShare();
      });
    }
  }

  document.addEventListener("selectionchange", () => {
    handleSelectionChange();
  });

  if (!isLoaded) {
    return <div className="Client"></div>;
  }

  if (problemData != null) {
    return (
      <div className="Client clientWaitingWrapper">
        <div className="clientWaiting">
          <img style={{ width: "100%" }} src={unicreditLogo} />
          <br />
          <br />

          <p className="text-center">
            <b>{problemData}</b>
          </p>
        </div>
      </div>
    );
  }

  if (webexError != null && IS_WEBEX) {
    return (
      <div className="Client clientWaitingWrapper">
        <div className="clientWaiting">
          <img style={{ width: "100%" }} src={unicreditLogo} />
          <br />
          <br />

          <p className="text-center">
            <b>Guest issuer error, please contact the administrator</b>
          </p>
        </div>
      </div>
    );
  }

  const toggleModalShareScreen = () => setModalShareScreen(!modalShareScreen);
  const toggleModalCloseCall = () => setModalCloseCall(!modalCloseCall);
  const toggleModalDisconnected = () => {
    modalDisconnectStatus.current = true;
    props.history.go(0);
  };
  function closeCallConfirm() {
    sendMessage("CUSTOMER_CLOSE_SESSION_TO_WS", {});
    // props.history.go(0);
    myVideo.current.srcObject.getTracks().forEach((t) => {
      t.stop();
    });
    myVideo.current.srcObject = null;
    peerRef.current.close();
    peerRef.current = null;
    setProblemData("You closed the call");
  }

  return (
    <div>
      {R4AToken ? (
        <SockJsClient // socket module
          url={SOCKET_URL + "?R4ASessionId=" + R4AToken}
          topics={[socketTopic]}
          ref={(client) => {
            setSocketClient(client);
          }}
          onConnect={onConnected}
          onDisconnect={console.log("Disconnected!")}
          onMessage={(msg) => onMessageReceived(msg)}
          debug={false}
        />
      ) : (
        ""
      )}
      {loadingPdfSpinner == true ? (
        <div className="modal fade" style={{ display: "block" }}>
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body text-center">
                <div className="loader"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {!isAdvisorConnected ? (
        <div className="Client clientWaitingWrapper">
          <div className="clientWaiting">
            <img style={{ width: "100%" }} src={unicreditLogo} />
            <br />
            <br />
            <p className="text-center">
              Waiting for <b>advisor</b> to connect...
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="Client">
            <ModalDisconnected
              modalDisconnected={modalDisconnected}
              toggleModalDisconnected={toggleModalDisconnected}
              type="customer"
            />
            <ModalShareScreen
              modalShareScreen={modalShareScreen}
              toggleModalShareScreen={toggleModalShareScreen}
              startShareScreen={startShareScreen}
            />
            <ModalCloseCall
              modalCloseCall={modalCloseCall}
              toggleModalCloseCall={toggleModalCloseCall}
              closeCallConfirm={closeCallConfirm}
            />
            <Container style={{ maxWidth: "100%" }}>
              <Row>
                <div className="leftPannel col-xl-8 col-lg-7 col-md-7">
                  {(filePDF != null) & (minifiedPdf == false) ? (
                    <div>
                      <PdfViewerCustomer
                        pdfZoom={pdfZoom}
                        pdfRotate={pdfRotate}
                        file={filePDF}
                        pageNumber={pdfPageNumber}
                        scrollTop={pdfScrollTop}
                        scrollLeft={pdfScrollLeft}
                        scrollHeight={pdfScrollHeight}
                        scrollWidth={pdfScrollWidth}
                        canMakeSelection={canMakeSelection}
                        actionMinimizePdf={actionMinimizePdf}
                      ></PdfViewerCustomer>
                    </div>
                  ) : (
                    ""
                  )}
                  <video
                    ref={remoteVideo}
                    playsInLine
                    className="leftPannelBigVideo"
                  ></video>
                  <img
                    src={stoppedVideoImg}
                    ref={remoteVideoImg}
                    className="noVideoLeftImg"
                    style={{ display: "none" }}
                  />
                </div>
                <div
                  className="paddingTopLogoLeftPannel col-xl-4 col-lg-5 col-md-5"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    style={{ width: "80%", marginBottom: "10px" }}
                    src={unicreditLogo}
                  />

                  {/* <div className="btnsGroupR4a">
                    {chatState == false ? (
                      <button
                        className="btnWrapper"
                        onClick={(e) => openChat()}
                      >
                        <img
                          className="iconElement clickableImg"
                          src={chatImgClosed}
                          style={{ display: "block" }}
                          data-html2canvas-ignore
                          alt="Open Chat"
                          ref={chatIcon}
                        />
                        <span className="chatTextClosed">Help Chat</span>
                        {chatMessages.length != 0 ? (
                          <Badge color="primary" className="fileHistoryBadge">
                            {chatMessages.filter((msg) => msg.seen === false).length}
                          </Badge>
                        ) : (
                          ""
                        )}
                      </button>
                    ) : (
                      <button
                        className="btnWrapper"
                        onClick={(e) => closeChat()}
                      >
                        <img
                          className="iconElement clickableImg"
                          src={chatImgOpened}
                          style={{ display: "block" }}
                          data-html2canvas-ignore
                          alt="Close Chat"
                          ref={chatIcon}
                        />
                        <span className="chatTextOpened">Help Chat</span>
                        {chatMessages.length != 0 ? (
                          <Badge color="primary" className="fileHistoryBadge">
                            {chatMessages.length}
                          </Badge>
                        ) : (
                          ""
                        )}
                      </button>
                    )}
                  </div> */}

                  <div ref={smallVideosWrapper}>
                    <video
                      ref={myVideo}
                      muted="true"
                      playsInLine
                      className="smallVideoClass"
                    ></video>
                    <img
                      src={stoppedVideoImg}
                      ref={myVideoImg}
                      className="noVideoSelfImg"
                      style={{ display: "none" }}
                    />
                    <video
                      ref={remoteVideoSmall}
                      playsInLine
                      className="smallVideoClass"
                      style={{ display: "none" }}
                    ></video>
                    <img
                      src={stoppedVideoImg}
                      ref={remoteVideoImgSmall}
                      className="noVideoSelfImg"
                      style={{ display: "none" }}
                    />
                  </div>
                  {IS_WEBEX == true ? (
                    <div
                      id="message-webex-widget"
                      ref={chatMessageWidget}
                      data-html2canvas-ignore
                      style={{ left: "3%", display: "none" }}
                    ></div>
                  ) : (
                    <div style={{ display: "none" }} ref={chatMessageWidget}>
                      <Chat
                        closeChat={closeChat}
                        chatMessages={chatMessages}
                        sendMessage={sendMessage}
                        name={customerName}
                      ></Chat>
                    </div>
                  )}
                  <div className="emptyDivFlexGrow"></div>
                  <div className="callButtonsSectionFlex">
                    <div className="callButtonsSectionInner">
                      {chatState == false ? (
                        <img
                          id="tooltipChat"
                          className="callButtonElementImg clickableImg"
                          src={chatImgClosed}
                          alt="Open Chat"
                          onClick={(e) => openChat()}
                        />
                      ) : (
                        <img
                          id="tooltipChat"
                          className="callButtonElementImg clickableImg"
                          src={chatImgOpened}
                          alt="Close Chat"
                          onClick={(e) => closeChat()}
                        />
                      )}
                      {chatMessages.filter((msg) => msg.seen === false)
                        .length != 0 ? (
                        <Badge color="primary" className="clientChatBadge">
                          {
                            chatMessages.filter((msg) => msg.seen === false)
                              .length
                          }
                        </Badge>
                      ) : (
                        ""
                      )}
                      <UncontrolledTooltip placement="top" target="tooltipChat">
                        {chatState == false ? "Open Chat" : "Close Chat"}
                      </UncontrolledTooltip>

                      {shareScreenState == false ? (
                        <img
                          id="tooltipShare"
                          className="callButtonElementImg clickableImg"
                          src={shareClosed}
                          alt="Open Share Screen"
                          onClick={(e) => {
                            toggleModalShareScreen();
                          }}
                        />
                      ) : (
                        <img
                          id="tooltipShare"
                          className="callButtonElementImg clickableImg"
                          src={shareOpened}
                          alt="Close Share Screen"
                          onClick={(e) => {
                            stopShareScreen();
                          }}
                        />
                      )}
                      <UncontrolledTooltip
                        placement="top"
                        target="tooltipShare"
                      >
                        {shareScreenState == false
                          ? "Start Share Screen"
                          : "Stop Share Screen"}
                      </UncontrolledTooltip>

                      {micState == true ? (
                        <img
                          id="tooltipMic"
                          className="callButtonElementImg clickableImg"
                          src={micOpen}
                          alt="Close Mic"
                          onClick={(e) => stopSendAudio()}
                        />
                      ) : (
                        <img
                          id="tooltipMic"
                          className="callButtonElementImg clickableImg"
                          src={micClosed}
                          alt="Open Mic"
                          onClick={(e) => startSendAudio()}
                        />
                      )}
                      <UncontrolledTooltip placement="top" target="tooltipMic">
                        {micState == true ? "Stop Mic" : "Open Mic"}
                      </UncontrolledTooltip>

                      {videoStateBool == true ? (
                        <img
                          id="tooltipVideo"
                          className="callButtonElementImg clickableImg"
                          src={videoOpen}
                          alt="Close Video"
                          onClick={(e) => stopSendVideo()}
                        />
                      ) : (
                        <img
                          id="tooltipVideo"
                          className="callButtonElementImg clickableImg"
                          src={videoClosed}
                          alt="Open Video"
                          onClick={(e) => startSendVideo()}
                        />
                      )}
                      <UncontrolledTooltip
                        placement="top"
                        target="tooltipVideo"
                      >
                        {videoStateBool == true ? "Stop Video" : "Start Video"}
                      </UncontrolledTooltip>

                      <img
                        id="tooltipCloseCall"
                        className="callButtonElementImg clickableImg"
                        src={closeCall}
                        alt="Close Call"
                        onClick={(e) => {
                          toggleModalCloseCall();
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="tooltipCloseCall"
                      >
                        Close Call
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>

            <div
              id="circleMouse"
              ref={advisorMouseRef}
              style={{ display: !isMouse ? "none" : "" }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
}

export default Client;
