import React from "react";
import "./../../components/chat/chat.css";
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge
} from "reactstrap";
import FilesHistoryElements from "./filesHistoryElement";



var today = new Date();

function FilesHistory(props) {
  return (
    <div className="">
      <div className="chat">
        <div className="helpChatWrapper">
          <Row>
            <Col xl="2"></Col>
            <Col xl="8" className="text-center">
              Files History
            </Col>
            <Col xl="2" className="text-right">
              <button
                className="chat-close"
                onClick={(e) => props.closeFileHistory()}
              >
                X
              </button>
            </Col>
          </Row>
        </div>
        <div className="contact bar">
          <div className="name"></div>

          <div className="seen">
            <b>
              Today at {("0" + today.getHours()).slice(-2)}:
              {(today.getMinutes() < 10 ? "0" : "") + today.getMinutes()}
            </b>
          </div>
        </div>
        <div className="messages" id="chat">
          {props.filesList.map((file, index) => {
            return (
              <FilesHistoryElements
                index={index}
                name={file.file.name}
                openArchivePdfFile={props.openArchivePdfFile}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FilesHistory;
