import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Webex from "webex";
import SockJsClient from "react-stomp";
import pdfIconImg from "./../../assets/pdfIcon.png";
import stoppedVideoImg from "./../../assets/stoppedVideoImg.png";

import PdfViewerAdvisor from "../../components/pdfViewer/pdfViewerAdvisor";
import adapter from "webrtc-adapter";
import Chat from "../../components/chat/chat";
import CallButtons from "./callButtons";
import FilesHistory from "./filesHistory";

import chatImgClosed from "./../../assets/chatClosed.png";
import chatImgOpened from "./../../assets/chatOpened.png";
import shareClosed from "./../../assets/shareClosed.png";
import shareOpened from "./../../assets/shareOpened.png";
import warningImg from "./../../assets/warning.png";
import micOpen from "./../../assets/micOpen.png";
import micClosed from "./../../assets/micClosed.png";
import micDisabled from "./../../assets/micDisabled.png";

import closeCall from "./../../assets/closeCall.png";

import videoOpen from "./../../assets/videoOpen.png";
import videoDisabled from "./../../assets/videoDisabled.png";

import videoClosed from "./../../assets/videoClosed.png";
import unicreditLogo from "./../../assets/unicreditLogo.png";

import fileHistoryClosed from "./../../assets/fileHistoryClosed.png";
import fileHistoryOpened from "./../../assets/fileHistoryOpened.png";
import uploadFile from "./../../assets/uploadFile.png";
import uploadFileDisabled from "./../../assets/uploadFileDisabled.png";

import ModalCloseCall from "./../../components/modals/ModalCloseCall";
import ModalFileErr from "./../../components/modals/ModalFileErr";
import ModalDisconnected from "./../../components/modals/ModalDisconnected";

import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge
} from "reactstrap"; // functions
import {
  selectNodes,
  onStateChange,
  onIceStateChange,
  receiveChannelCallback,
  handleICECandidateEvent,
  handleReceiveMessage,
  handleNewICECandidateMsg,
  handleAnswer,
  sendPdfData
} from "./actions";

const SOCKET_URL = window.__RUNTIME_CONFIG__.SOCKET_URL_ADVISOR;
const TURN_URL = window.__RUNTIME_CONFIG__.TURN_SERVER_ADVISOR_URL;
const TURN_USER = window.__RUNTIME_CONFIG__.TURN_SERVER_USER;
const TURN_PASSWORD = window.__RUNTIME_CONFIG__.TURN_SERVER_PASSWORD;
const BASE_URL = window.__RUNTIME_CONFIG__.BASE_URL;
const IS_WEBEX = window.__RUNTIME_CONFIG__.IS_WEBEX === "true";

function Advisor(props) {
  if (IS_WEBEX) {
    if (
      // TODO check why is not working
      (localStorage.getItem("accessTokenWebexRemovalDate") < new Date()) &
      (localStorage.getItem("accessTokenWebexRemovalDate") != null)
    ) {
      localStorage.removeItem("accessTokenWebex");
    }
  }

  if (localStorage.getItem("r4aID") == null) {
    props.history.push("/404");
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [isAdvisorConnected, setIsAdvisorConnected] = useState(false);
  const [socketTopic, setSocketTopic] = useState("");
  const [meetingQueueName, setMeetingQueueName] = useState("");
  const [customerStatus, setCustomerStatus] = useState(false);
  const [R4AToken, setR4AToken] = useState("");
  const [myMeeting, setMyMeeting] = useState(null);
  const [audioState, setAudioState] = useState("audio on");
  const [videoState, setVideoState] = useState("video on");
  const [filePDF, setFilePDF] = useState(null);
  const [isAdvisorSelection, setIsAdvisorSelection] = useState(true);
  const [problemData, setProblemData] = useState(null);
  const [modalDisconnected, setModalDisconnected] = useState(false);

  const [modalCloseCall, setModalCloseCall] = useState(false);
  const [modalFileErr, setModalFileErr] = useState(false);

  const [fileHistoryState, setFileHistoryState] = useState(false);
  const [chatState, setChatState] = useState(false);
  const [micState, setMicState] = useState(true);
  const [videoStateBool, setVideoStateBool] = useState(true);

  const [isMouse, setIsMouse] = useState(false);

  const [pdfPageNumber, setPageNumber] = useState(1);
  const [pdfRotate, setPdfRotate] = useState(0);

  const [webexError, setWebexError] = useState(false);

  const [message, setMessage] = useState("You server message here.");
  const [socketClient, setSocketClient] = useState(null);

  const [webexStatus, setWebexStatus] = useState("");
  const [roomIdWebex, setRoomIdWebex] = useState("");
  const [minifiedPdf, setMinifiedPdf] = useState(false);

  const [filesList, setFilesList] = useState([]);

  const [chatMessages, setChatMessages] = useState([]);

  let mediaRecorder = useRef();

  let recorderAudioContext = useRef();

  let peerRemoteAudioStream = useRef();
  let advisorMouseRef = useRef();

  let localAudioContext = useRef();

  let remoteAudioContext = useRef();

  let dest = useRef();

  const scrollTop = useRef(0);
  const scrollLeft = useRef(0);
  const filePdfState = useRef(false);

  let smallVideosWrapper = useRef(null);

  const r4aID = localStorage.getItem("r4aID");

  console.log("r4aID", r4aID);

  const [advisorName, setAdvisorName] = useState("");

  let chatMessageWidget = useRef();

  let isAdvisorSelectionIntern = useRef(true);

  let remoteShareStream = useRef();

  let advisorMainDiv = useRef();

  let remoteVideoImg = useRef();
  let myVideoImg = useRef();
  let remoteVideoImgSmall = useRef();
  let remoteVideoSmall = useRef();
  let modalDisconnectStatus = useRef(false);

  let localAudioStream = useRef(null);

  let webexSDK = null;

  if (IS_WEBEX) {
    if (localStorage.getItem("accessTokenWebex")) {
      webexSDK = Webex.init({
        config: {
          meetings: {
            reconnection: {
              enabled: true
            }
          }
        },

        credentials: {
          access_token: localStorage.getItem("accessTokenWebex")
        }
      });
    } else {
      webexSDK = Webex.init({
        config: {
          credentials: {
            //Emanuel
            client_id:
              "Cfdd381c7b3c643ce9e69d18cdd5b785104bb0e89dc585fa306bed6e45399cc56",
            redirect_uri: `${window.location.protocol}//${window.location.host}/redirect/advisor`,
            scope: "spark:all spark:kms"
          }
        }
      });
      initWebexClient();
    }
  }

  const [webexToken, setWebexToken] = useState("");

  const videoGrid = useRef();

  const SEC_30_S = 20000;

  useEffect(() => {
    const interval = setInterval(() => {
      sendMessage("ADVISOR_HEART_BEAT_TO_WS", {});
    }, SEC_30_S);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [socketClient]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  });

  const alertUser = (event) => {
    if (modalDisconnectStatus.current == false) {
      mediaRecorder.current.stop();
      sendMessage("ADVISOR_DISCONNECT_TO_WS", {});
    }
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://code.s4d.io/widget-space/production/bundle.js";
    script.async = false;

    document.body.appendChild(script);

    setIsLoaded(true);
    if (localStorage.getItem("accessTokenWebex") || !IS_WEBEX) {
      console.log("backend URL: " + BASE_URL);
      const headers = { RemoteMeetingId: r4aID };

      if (localStorage.getItem("r4asessionid") != "current") {
        headers.R4ASessionId = localStorage.getItem("r4asessionid");
      }

      axios
        .get(BASE_URL + "advisor/meeting/" + r4aID + "/wsinfo", {
          headers: headers
        })
        .then((res) => {
          const { meetingTopicName, meetingQueueName } = res.data;
          setAdvisorName(res.data.advisorName);
          setMeetingQueueName(meetingQueueName);
          setSocketTopic(meetingTopicName);
          setR4AToken(res.headers["r4asessionid"] || localStorage.getItem("r4asessionid"));
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              setProblemData(error.response.data);
            }
          } else if (error.request) {
            setProblemData("Service is unavailable");
          }
        });
    }
  }, []);

  let chatIcon = useRef();
  let isMessageBoxOpened = useRef(false);
  const lastTextSelection = useRef({});

  function openChat() {
    if (chatMessageWidget.current != null) {
      closeFileHistory();
      smallVideosWrapper.current.style.display = "none";

      chatMessageWidget.current.style.display = "block";
      setChatState(true);

      if (!isMessageBoxOpened.current & IS_WEBEX) {
        let close = document.createElement("span");
        close.classList.add("close");
        close.style.padding = "0px 12px 0px 0px";
        close.appendChild(document.createTextNode("x"));
        chatMessageWidget.current
          .querySelector(".webex-title-bar")
          .appendChild(close);

        close.addEventListener("click", (e) => {
          chatIcon.current.style.display = "block";
          setChatState(false);
        });
      }
      setChatMessages(
        chatMessages.map((message) => {
          return {
            ...message,
            seen: true
          };
        })
      );
      isMessageBoxOpened.current = true;
    }
  }

  function closeChat() {
    if (
      chatMessageWidget.current != null &&
      chatIcon.current !== null &&
      smallVideosWrapper.current != null
    ) {
      chatMessageWidget.current.style.display = "none";
      chatIcon.current.style.display = "block";
      setChatState(false);
      smallVideosWrapper.current.style.display = "block";
      setChatMessages(
        chatMessages.map((message) => {
          return {
            ...message,
            seen: true
          };
        })
      );
    }
  }

  const peerRef = useRef();
  const sendChannel = useRef();
  const receiveChannel = useRef();

  const sendPdfChannel = useRef();
  const sendMouseChannel = useRef();
  const sendEventChannel = useRef();

  const receiveChannelEvent = useRef();

  const audioChunks = useRef([]);

  async function callUser() {
    peerRef.current = await createPeer();
  }

  let remoteVideo = useRef();
  let myVideo = useRef();

  async function createPeer() {
    const peer = new RTCPeerConnection({
      iceServers: [
        {
          url: TURN_URL + "?transport=tcp",
          credential: TURN_PASSWORD,
          username: TURN_USER
        },
        {
          url: TURN_URL + "?transport=udp",
          credential: TURN_PASSWORD,
          username: TURN_USER
        }
      ],
      iceTransportPolicy: 'relay'
    });

    const dataChannelOptions = {
      ordered: true,
      maxPacketLifeTime: 3000 // in milliseconds
    };

    sendChannel.current = peer.createDataChannel(
      "sendContent",
      dataChannelOptions
    );
    sendChannel.current.onmessage = handleReceiveMessage;

    sendPdfChannel.current = peer.createDataChannel(
      "sendPdfContent",
      dataChannelOptions
    );
    sendPdfChannel.current.onmessage = handleReceiveMessage;

    sendMouseChannel.current = peer.createDataChannel("sendMouseContent");
    sendMouseChannel.current.onmessage = handleReceiveMessage;

    sendEventChannel.current = peer.createDataChannel(
      "sendEventContent",
      dataChannelOptions
    );
    sendEventChannel.current.onmessage = handleReceiveMessage;

    // peer.addTransceiver
    if (!IS_WEBEX) {
      const constraints = {
        audio: true,
        video: true
      };
      let stream = null;

      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        /* use the stream */
        addVideoStream(myVideo.current, stream);
        stream.getTracks().forEach((track) => peer.addTrack(track, stream));
      } catch(err) {
        /* handle the error */
        console.warn(err);
      }


      peer.ontrack = (e) => {
        console.log("gotRemoteStream", e.track, e.streams[0]);
        console.log("ONTRACK", e);
        addVideoStream(remoteVideo.current, e.streams[0]);
        addVideoStream(remoteVideoSmall.current, e.streams[0]);

        if (peerRemoteAudioStream.current == null) {
          peerRemoteAudioStream.current = e.streams[0];
          navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
              localAudioStream.current = stream;
              recorderAudioContext.current = new AudioContext();
              localAudioContext.current =
                recorderAudioContext.current.createMediaStreamSource(
                  localAudioStream.current
                );
              remoteAudioContext.current =
                recorderAudioContext.current.createMediaStreamSource(
                  peerRemoteAudioStream.current
                );

              dest.current =
                recorderAudioContext.current.createMediaStreamDestination();

              localAudioContext.current.connect(dest.current);
              remoteAudioContext.current.connect(dest.current);

              mediaRecorder.current = new MediaRecorder(dest.current.stream);

              mediaRecorder.current.addEventListener(
                "dataavailable",
                (event) => {
                  audioChunks.current.push(event.data);
                  console.log("intra in dataavailable");
                  const audioBlob = new Blob(audioChunks.current, {
                    type: "audio/webm"
                  });
                  audioChunks.current = [];
                  var file = new File([audioBlob], "recording.webm");
                  const formData = new FormData();
                  formData.append("file", file);
                  axios
                    .post(BASE_URL + "advisor/meeting/recording", formData, {
                      headers: {
                        R4ASessionId: R4AToken,
                        "Content-Type": "multipart/form-data"
                      }
                    })
                    .then((res) => {
                      console.log("File uploaded");
                    })
                    .catch(function (error) {
                      if (error.response) {
                        if (error.response.status === 403) {
                          setProblemData(error.response.data);
                        }
                      } else if (error.request) {
                        setProblemData("Service is unavailable");
                      }
                    });
                }
              );

              mediaRecorder.current.start(20000);
            });
        }

        if (filePdfState.current == true) {
          remoteVideoSmall.current.style.display = "block";
        }
      };
    }

    const offerOptions = {
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1
    };
    peer
      .createOffer(offerOptions)
      .then((offer) => {
        return peer.setLocalDescription(offer);
      })
      .then(() => {
        sendMessage("ADVISOR_OFFER_TO_WS", { sdp: peer.localDescription });
      })
      .catch((e) => console.log(e));

    peer.ondatachannel = (e) =>
      receiveChannelCallback(
        e,
        receiveChannel.current,
        receiveChannelEvent.current,
        handleReceiveEventMessage
      );
    peer.onicecandidate = (e) => handleICECandidateEvent(e, sendMessage);
    peer.onconnectionstatechange = (e) => onStateChange(peer, e);
    peer.oniceconnectionstatechange = (e) => onIceStateChange(peer, e);

    return peer;
  }

  function handleReceiveEventMessage(event) {
    const message = JSON.parse(event.data);
    const eventType = message.event;
    const data = message.msg;
    console.log("event.data", event.data);
    console.log("data", data);
    console.log("eventType", eventType);
    if (eventType === "TEXT_SELECT") {
      lastTextSelection.current = data;

      try {
        let textLayerContainer = document.getElementsByClassName("textLayer");
        if (textLayerContainer) {
          if (textLayerContainer[0] && textLayerContainer[0].children) {
            let elem = Array.from(textLayerContainer[0].children).filter(
              (t) =>
                t.innerHTML === data.fromNodeValue ||
                t.innerHTML === data.toNodeValue
            );

            selectNodes(elem, data.from, data.to);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else if (eventType === "ASK_FOR_SCROLL") {
      const scrollBuffer = scrollTop.current;

      actionPdfScroll({
        scrollTop: 0,
        scrollLeft: 0
      });
      scrollTop.current = scrollBuffer;
      setTimeout(function () {
        actionPdfScroll({
          scrollTop: scrollTop.current,
          scrollLeft: scrollLeft.current
        });
      }, 1000);
    } else if (eventType === "STOP_VIDEO") {
      if (filePdfState.current == false) {
        remoteVideo.current.style.display = "none";
        remoteVideoImg.current.style.display = null;
      } else {
        remoteVideoSmall.current.style.display = "none";
        remoteVideoImgSmall.current.style.display = "block";
      }
    } else if (eventType === "START_VIDEO") {
      if (filePdfState.current == false) {
        remoteVideo.current.style.display = null;
        remoteVideoImg.current.style.display = "none";
      } else {
        remoteVideoSmall.current.style.display = "block";
        remoteVideoImgSmall.current.style.display = "none";
      }
    }
  }

  let onConnected = () => {
    console.log("Connected!!");
  };

  function initiateWidget(roomId) {
    // Init a new widget
    window.webex.widget(chatMessageWidget.current).spaceWidget({
      accessToken: localStorage.getItem("accessTokenWebex"),
      destinationId: roomId,
      destinationType: "spaceId",
      spaceActivities: {
        files: false,
        meet: false,
        message: true,
        people: false
      },
      initialActivity: "message"
    });
  }

  function startMeeting() {
    setWebexStatus(`Creating room in Webex...`);
    webexSDK.rooms.create({ title: "Advisory Room" }).then((room) => {
      let roomId = room.id;
      initiateWidget(roomId);
      setRoomIdWebex(roomId);
      webexSDK.meetings
        .create(roomId)
        .then((meeting) => {
          bindMeetingEvents(meeting);
          setMyMeeting(meeting);

          setWebexStatus(`Joining Webex meeting...`);
          return joinMeeting(meeting, roomId);
        })
        .catch((error) => {
          // Report the error
          console.error(error);
        });
    });
  }

  function initWebexClient() {
    setWebexStatus("Webex initialization...");

    webexSDK.authorization.initiateLogin();

    setIsAdvisorConnected(true);
  }

  useEffect(() => {
    if (IS_WEBEX) {
      if (localStorage.getItem("accessTokenWebex") && customerStatus) {
        webexSDK.once("ready", () => {
          setWebexStatus("Webex ready");
          setWebexStatus("Webex can authorize " + webexSDK.canAuthorize);
          if (webexSDK.canAuthorize) {
            // Listen for added meetings
            webexSDK.meetings.on("meeting:added", (addedMeetingEvent) => {
              if (addedMeetingEvent.type === "CREATED") {
                setMyMeeting(addedMeetingEvent.meeting);
              }
            });

            setWebexStatus(`Registering in Webex...`);
            webexSDK.meetings
              .register()
              .catch((err) => {
                console.error(err);
                alert(err);
                throw err;
              })
              .then(() => startMeeting())
              .then(() => {});
          }
        });
      }
    }
  }, [customerStatus]);

  function addVideoStream(video, stream) {
    video.srcObject = stream;
    video.addEventListener("click", function (e) {
      var elem = document.querySelector(".fullScreenVideo");
      if (elem != undefined) {
        document
          .querySelectorAll("video")
          .forEach((e) => e.classList.remove("fullScreenVideo"));
        remoteVideo.current.classList.add("remoteVideoClass");
      }
      if (elem != e.target) {
        e.target.classList.add("fullScreenVideo");
        remoteVideo.current.classList.remove("remoteVideoClass");
      }
    });
    video.addEventListener("loadedmetadata", () => {
      video.play();
    });
    // videoGrid.current.append(video);
  }

  let screenShareVideo = useRef();

  function bindMeetingEvents(meeting) {
    meeting.on("error", (err) => {
      console.error(err);
    });

    meeting.on("meeting:startedSharingRemote", () => {
      // Set the source of the video element to the previously stored stream
      screenShareVideo.current = document.createElement("video");
      screenShareVideo.current.style.width = "425px";
      screenShareVideo.current.style.height = "275px";
      addVideoStream(screenShareVideo.current, remoteShareStream.current);
    });

    meeting.on("meeting:stoppedSharingRemote", () => {
      screenShareVideo.current.remove();
    });

    // Handle media streams changes to ready state
    meeting.on("media:ready", (media) => {
      if (!media) {
        return;
      }

      if (media.type === "local") {
        addVideoStream(myVideo.current, media.stream);
      }
      if (media.type === "remoteVideo") {
        remoteVideo.current = document.createElement("video");
        remoteVideo.current.classList.add("remoteVideoClass");
        addVideoStream(remoteVideo.current, media.stream);
      }
      if (media.type === "remoteAudio") {
        let remoteAudio = document.createElement("audio");
        remoteAudio.setAttribute("autoplay", "");
        remoteAudio.setAttribute("playsInLine", "");
        remoteAudio.srcObject = media.stream;
        videoGrid.current.append(remoteAudio);
      }
      if (media.type === "remoteShare") {
        // Remote share streams become active immediately on join, even if nothing is being shared
        remoteShareStream.current = media.stream;
      }
    });

    // Handle media streams stopping
    meeting.on("media:stopped", (media) => {
      // Remove media streams
      if (media.type === "local") {
        myVideo.current.remove();
      }
      if (media.type === "remoteVideo") {
        document.getElementById("remote-view-video").srcObject = null;
      }
      if (media.type === "remoteAudio") {
        document.getElementById("remote-view-audio").srcObject = null;
      }
      if (media.type === "remoteShare") {
        screenShareVideo.current.remove();
      }
    });
  }

  function joinMeeting(meeting, roomId) {
    return meeting.join().then(() => {
      return meeting
        .getSupportedDevices({ sendAudio: true, sendVideo: true })
        .then(({ sendAudio, sendVideo }) => {
          const mediaSettings = {
            receiveVideo: true,
            receiveAudio: true,
            receiveShare: true,
            sendVideo: true,
            sendAudio: true,
            sendShare: false
          };

          // Get our local media stream and add it to the meeting
          return meeting.getMediaStreams(mediaSettings).then((mediaStreams) => {
            const [localStream, localShare] = mediaStreams;

            meeting.addMedia({
              localShare,
              localStream,
              mediaSettings
            });
            sendMessage("ADVISOR_SEND_WEBEX_ROOM_ID_TO_WS", {
              webexRoomId: roomId
            });
            setWebexStatus("CONNECTED");
          });
        });
    });
  }

  let onMessageReceived = async (msg) => {
    setMessage(msg);
    console.log(msg);
    if (msg.eventType === "WS_CUSTOMER_CLOSE_SESSION_TO_ADVISOR") {
      myVideo.current.srcObject.getTracks().forEach((t) => {
        t.stop();
      });
      myVideo.current.srcObject = null;
      peerRef.current.close();
      peerRef.current = null;
      setProblemData("The customer has closed the session");
    } else if (msg.eventType === "WS_CUSTOMER_CONNECTED_TO_ADVISOR") {
      if (IS_WEBEX) {
        if (!localStorage.getItem("accessTokenWebex")) {
          // initWebexClient();
        } else {
          setIsAdvisorConnected(true);
          setCustomerStatus(true);
        }
        await callUser(msg.userId);
      } else {
        setIsAdvisorConnected(true);
        setCustomerStatus(true);
        await callUser(msg.userId);
      }
    } else if (msg.eventType == "WS_ADD_TO_MEETING_TO_ADVISOR") {
      if (IS_WEBEX) {
        let membership;
        if (msg.message.customerWebexId) {
          membership = {
            personId: msg.message.customerWebexId,
            roomId: roomIdWebex
          };
        } else {
          membership = {
            personEmail: message.webexEmail,
            roomId: roomIdWebex
          };
        }

        webexSDK.memberships.create(membership).then(() => {
          sendMessage("ADVISOR_CUSTOMER_ADDED_TO_MEETING_TO_WS", {});
        });
      }
    } else if (msg.eventType === "WS_CUSTOMER_DISCONNECTED_TO_ADVISOR") {
      if (isAdvisorConnected) {
        // alert(
        //   "Customer has disconnected. You will be redirected to landing page"
        // );
        // props.history.go(0);
        mediaRecorder.current.stop();
        setModalDisconnected(true);
      }
    } else if (msg.eventType === "WS_ANSWER_TO_ADVISOR") {
      handleAnswer(msg.message.sdp, peerRef.current);
    } else if (msg.eventType === "WS_CUSTOMER_ICE_CANDIDATE_TO_ADVISOR") {
      handleNewICECandidateMsg(msg.message.iceCandidate, peerRef.current);
    } else if (msg.eventType == "WS_MEETING_CLOSED_TO_ALL") {
      props.history.go(0);
    } else if (msg.eventType == "WS_NO_GUEST_TOKEN_TO_ALL") {
      if (IS_WEBEX) {
        setWebexError(true);
      }
    } else if (msg.eventType == "WS_SEND_CHAT_MESSAGE_TO_ALL") {
      setChatMessages([
        ...chatMessages,
        {
          msg: msg.message.message,
          sentBy: msg.message.sentBy,
          seen: chatState
        }
      ]);
    }
  };

  let sendMessage = (event, msg) => {
    const payloadToSend = JSON.stringify({ message: msg, eventType: event });
    console.log("SEND TO SERVER SOCKET", payloadToSend);
    try {
      socketClient.sendMessage(meetingQueueName, payloadToSend);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  function stopSendAudio() {
    setMicState(false);

    if (!IS_WEBEX & (myVideo.current != null)) {
      if (localAudioStream.current != null) {
        localAudioStream.current.getTracks().forEach((t) => {
          if (t.kind == "audio") {
            t.enabled = false;
          }
        });
      }
      myVideo.current.srcObject.getTracks().forEach((t) => {
        if (t.kind == "audio") {
          t.enabled = false;
        }
      });
    }
    if (myMeeting) {
      myMeeting.muteAudio().then(() => {
        setAudioState("audio off");
      });
    }
  }

  function startSendAudio() {
    setMicState(true);
    if (!IS_WEBEX & (myVideo.current != null)) {
      if (localAudioStream.current != null) {
        localAudioStream.current.getTracks().forEach((t) => {
          if (t.kind == "audio") {
            t.enabled = true;
          }
        });
      }
      myVideo.current.srcObject.getTracks().forEach((t) => {
        if (t.kind == "audio") {
          t.enabled = true;
        }
      });
    }
    if (myMeeting) {
      myMeeting.unmuteAudio().then(() => {
        setAudioState("audio on");
      });
    }
  }

  function stopSendVideo() {
    setVideoStateBool(false);
    if (!IS_WEBEX & (myVideo.current != null)) {
      myVideo.current.srcObject.getTracks().forEach((t) => {
        if (t.kind == "video") {
          t.enabled = false;
          sendEventChannel.current.send(
            JSON.stringify({
              event: "STOP_VIDEO",
              msg: {}
            })
          );
          myVideo.current.style.display = "none";
          myVideoImg.current.style.display = null;
        }
      });
    }
    if (myMeeting) {
      myMeeting.muteVideo().then(() => {
        setVideoState("video off");
      });
    }
  }

  function startSendVideo() {
    setVideoStateBool(true);
    if (!IS_WEBEX & (myVideo.current != null)) {
      myVideo.current.srcObject.getTracks().forEach((t) => {
        if (t.kind == "video") {
          t.enabled = true;
          sendEventChannel.current.send(
            JSON.stringify({
              event: "START_VIDEO",
              msg: {}
            })
          );
          myVideo.current.style.display = null;
          myVideoImg.current.style.display = "none";
        }
      });
    }
    if (myMeeting) {
      myMeeting.unmuteVideo().then(() => {
        setVideoState("video on");
      });
    }
  }

  function changeStylePdfClose() {}

  function changeStylePdfOpen() {
    if (videoGrid.current != null) {
      videoGrid.current.classList.add("videoGridWithPdf");
      document
        .querySelectorAll("video")
        .forEach((e) => e.classList.remove("remoteVideoClass"));
      document
        .querySelectorAll("video")
        .forEach((e) => e.classList.remove("fullScreenVideo"));
    }
  }

  function onFileChange(event) {
    if (event.target.files[0]) {
      if (!event.target.files[0].name.includes(".pdf")) {
        toggleModalFileErr();
        return;
      }
      if ((filePDF != null) & (sendEventChannel.current != null)) {
        actionClosePdf();
      }
      if (event.target.files[0] != null) {
        let isOk = true;
        // for (let i = 0; i < filesList.length; i++) {
        //   if (event.target.files[0].name == filesList[i].file.name) {
        //     isOk = false;
        //   }
        // }
        if (isOk) {
          if (remoteVideoSmall.current != null && remoteVideo.current != null) {
            remoteVideoSmall.current.style.display = "block";
            remoteVideo.current.style.display = "none";
          }
          filePdfState.current = true;

          setMinifiedPdf(false);
          changeStylePdfOpen();
          isAdvisorSelectionIntern.current = true;
          setFilePDF(event.target.files[0]);
          setPageNumber(1);
          setPdfRotate(0);
        }
      }
      event.target.value = null;
      event.target.files = null;
    }
  }

  function uploadSharedDocument(file) {
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(BASE_URL + "advisor/meeting/document", formData, {
        headers: {
          R4ASessionId: R4AToken,
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => {
        console.log("File uploaded");
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            setProblemData(error.response.data);
          }
        } else if (error.request) {
          setProblemData("Service is unavailable");
        }
      });
  }

  function triggerSendPdfToCustomer() {
    if ((filePDF != null) & (sendPdfChannel.current != null)) {
      sendPdfData(filePDF, sendPdfChannel.current);

      if (sendEventChannel.current != null) {
        sendEventChannel.current.send(
          JSON.stringify({ event: "PAGE_CHANGE", msg: pdfPageNumber })
        );

        sendEventChannel.current.send(
          JSON.stringify({ event: "ROTATE_PDF", msg: pdfRotate })
        );
      }
      uploadSharedDocument(filePDF);
      actionAddToHistory();
    }
  }

  function openArchivePdfFile(index) {
    if (filePDF != null) {
      actionClosePdf();
    }
    if (filePDF == null) {
      if (remoteVideoSmall.current != null && remoteVideo.current != null) {
        remoteVideoSmall.current.style.display = "block";
        remoteVideo.current.style.display = "none";
      }
      filePdfState.current = true;
      setMinifiedPdf(false);
      changeStylePdfOpen();
      setPageNumber(filesList[index].pageNumber); // TODO
      setPdfRotate(filesList[index].rotate);
      isAdvisorSelectionIntern.current = true;
      setFilePDF(filesList[index].file);
      filePdfState.current = true;
    }
  }

  function sendAdvisorMouseMove(e) {
    if (isMouse) {
      if (sendMouseChannel.current) {
        sendMouseChannel.current.send(
          e.x + ";" + e.y + ";" + window.innerWidth + ";" + window.innerHeight
        );
        advisorMouseRef.current.style.left = e.x + "px";
        advisorMouseRef.current.style.top = e.y + "px";
      }
    }
  }

  function handleReceiveMouseMessage(event) {
    if (advisorMouseRef.current != null) {
      const message = event.data;
      const coords = message.split(";");
      advisorMouseRef.current.style.left =
        (coords[0] * window.innerWidth) / coords[2] + "px";
      advisorMouseRef.current.style.top =
        (coords[1] * window.innerHeight) / coords[3] + "px";
    }
  }

  function actionPageChange(e) {
    console.log("page change" + e);
    setPageNumber(e);

    if (sendEventChannel.current != null) {
      sendEventChannel.current.send(
        JSON.stringify({ event: "PAGE_CHANGE", msg: e })
      );
    }
  }

  function actionZoomPdf(e) {
    if (sendEventChannel.current != null) {
      sendEventChannel.current.send(
        JSON.stringify({ event: "ZOOM_PDF", msg: e })
      );
    }
  }

  function actionRotatePdf(e) {
    setPdfRotate(e);
    if (sendEventChannel.current != null) {
      sendEventChannel.current.send(
        JSON.stringify({ event: "ROTATE_PDF", msg: e })
      );
    }
  }

  function actionPdfScroll(e) {
    scrollLeft.current = e.scrollLeft;
    scrollTop.current = e.scrollTop;

    if (sendEventChannel.current != null) {
      sendEventChannel.current.send(
        JSON.stringify({ event: "PAGE_SCROLL", msg: e })
      );
    }
  }

  function actionAddToHistory() {
    let isOk = true;
    for (let i = 0; i < filesList.length; i++) {
      if (filePDF.name == filesList[i].file.name) {
        isOk = false;
      }
    }
    if (isOk == true) {
      const newArr = [
        ...filesList,
        { file: filePDF, pageNumber: pdfPageNumber, rotate: pdfRotate }
      ];
      setFilesList(newArr);
    }
  }

  function actionClosePdf() {
    console.log("close pdf");

    for (let i = 0; i < filesList.length; i++) {
      if (filePDF.name == filesList[i].file.name) {
        const newArr = filesList.filter(
          (item) => item.file.name !== filePDF.name
        );
        newArr.push({
          file: filePDF,
          pageNumber: pdfPageNumber,
          rotate: pdfRotate
        });
        setFilesList(newArr);
      }
    }
    
    changeStylePdfClose();
    setFilePDF(null);
    filePdfState.current = false;
    if (sendEventChannel.current != null) {
      sendEventChannel.current.send(JSON.stringify({ event: "CLOSE_PDF" }));
      if (remoteVideo.current && remoteVideoSmall.current) {
        remoteVideo.current.srcObject = remoteVideoSmall.current.srcObject;
        remoteVideo.current.style.display = "block";
      }
      remoteVideoSmall.current.style.display = "none";
      showMouse(false);
    }
  }

  function actionStopPdfShare() {
    if (sendEventChannel.current != null) {
      sendEventChannel.current.send(JSON.stringify({ event: "CLOSE_PDF" }));
      setIsMouse(false);
      showMouse(false);
    }
  }

  function actionMinimizePdf() {
    changeStylePdfClose();
    setMinifiedPdf(true);
  }

  document.onmousemove = (e) => {
    sendAdvisorMouseMove(e);
  };

  function showMouse(sw) {
    setIsMouse(sw);
    if (sw) {
      sendEventChannel.current.send(
        JSON.stringify({ event: "START_MOUSE", msg: "" })
      );
    } else {
      sendEventChannel.current.send(
        JSON.stringify({ event: "STOP_MOUSE", msg: "" })
      );
    }
  }

  function handleSelectionChange() {
    let selection = document.getSelection();
    if (isAdvisorSelectionIntern.current) {
      try {
        console.log(JSON.stringify({
          event: "TEXT_SELECT",
          msg: {
            fromNodeValue: selection.anchorNode.wholeText,
            toNodeValue: selection.focusNode.wholeText,
            from: selection.anchorOffset,
            to: selection.focusOffset
          }
        }));
        sendEventChannel.current.send(
          JSON.stringify({
            event: "TEXT_SELECT",
            msg: {
              fromNodeValue: selection.anchorNode.wholeText,
              toNodeValue: selection.focusNode.wholeText,
              from: selection.anchorOffset,
              to: selection.focusOffset
            }
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  function setWhoCanSelect(isAdvisor) {
    if (isAdvisor) {
      setIsAdvisorSelection(true);
      isAdvisorSelectionIntern.current = true;
      sendEventChannel.current.send(
        JSON.stringify({
          event: "ADVISOR_SELECT",
          msg: {}
        })
      );
      console.log("intra pe advisor");
    } else {
      setIsAdvisorSelection(false);
      isAdvisorSelectionIntern.current = false;

      sendEventChannel.current.send(
        JSON.stringify({
          event: "CUSTOMER_SELECT",
          msg: {}
        })
      );
      console.log("intra pe customer");
    }
  }

  document.addEventListener("selectionchange", () => {
    handleSelectionChange();
  });

  function openFileHistory() {
    setFileHistoryState(true);
    closeChat();
    if (smallVideosWrapper.current) {
      smallVideosWrapper.current.style.display = "none";
    }
  }

  function closeFileHistory() {
    setFileHistoryState(false);
    if (smallVideosWrapper.current) {
      smallVideosWrapper.current.style.display = "block";
    }
  }

  const toggleModalCloseCall = () => setModalCloseCall(!modalCloseCall);
  const toggleModalFileErr = () => setModalFileErr(!modalFileErr);
  const toggleModalDisconnected = () => {
    modalDisconnectStatus.current = true;
    props.history.go(0);
  };
  function closeCallConfirm() {
    mediaRecorder.current.stop();
    sendMessage("ADVISOR_CLOSE_MEETING_TO_WS", {});
    // props.history.go(0);
    myVideo.current.srcObject.getTracks().forEach((t) => {
      t.stop();
    });
    myVideo.current.srcObject = null;
    peerRef.current.close();
    peerRef.current = null;

    setProblemData("You closed the call");
  }

  if (!isLoaded) {
    return <div className="Advisor"></div>;
  }

  if (problemData != null) {
    return (
      <div className="Advisor clientWaitingWrapper">
        <div className="clientWaiting">
          <img style={{ width: "100%" }} src={unicreditLogo} />
          <br />
          <br />

          <p className="text-center">
            <b>{problemData}</b>
          </p>
        </div>
      </div>
    );
  }
  if (webexError != null && IS_WEBEX) {
    return (
      <div className="Advisor clientWaitingWrapper">
        <div className="clientWaiting">
          <img style={{ width: "100%" }} src={unicreditLogo} />
          <br />
          <br />

          <p className="text-center">
            <b>Guest issuer error, please contact the administrator</b>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ModalDisconnected
        modalDisconnected={modalDisconnected}
        toggleModalDisconnected={toggleModalDisconnected}
        type="advisor"
      />
      <ModalCloseCall
        modalCloseCall={modalCloseCall}
        toggleModalCloseCall={toggleModalCloseCall}
        closeCallConfirm={closeCallConfirm}
      />
      <ModalFileErr
        modalFileErr={modalFileErr}
        toggleModalFileErr={toggleModalFileErr}
      />

      {R4AToken ? (
        <SockJsClient // socket module
          url={SOCKET_URL + "?R4ASessionId=" + R4AToken}
          topics={[socketTopic]}
          ref={(client) => {
            setSocketClient(client);
          }}
          onConnect={onConnected}
          onDisconnect={console.log("Disconnected!")}
          onMessage={(msg) => onMessageReceived(msg)}
          debug={false}
        />
      ) : (
        ""
      )}
      {!isAdvisorConnected ? (
        <>
          {filePDF == null && filesList.length == 0 ? (
            <>
              <div className="Advisor clientWaitingWrapper">
                <div className="clientWaiting">
                  <img style={{ width: "100%" }} src={unicreditLogo} />
                  <br />
                  <br />

                  <p className="text-center">
                    <b>Waiting for Customer to connect</b>
                  </p>
                </div>
              </div>

              <div className="callButtonsSection advisorCallBtnsNoConnectedAndNoPdf">
                <div className="callButtonsSectionInner">
                  <label for="actual-btn">
                    <img
                      className="callButtonElementImg clickableImg"
                      src={uploadFile}
                      alt="Upload File"
                    />
                  </label>
                  <input
                    id="actual-btn"
                    onChange={onFileChange}
                    type="file"
                    hidden
                  />
                  <img
                    className="callButtonElementImg clickableImg"
                    src={closeCall}
                    alt="Close Call"
                    onClick={(e) => {
                      toggleModalCloseCall();
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <Container style={{ maxWidth: "100%" }}>
              <Row>
                <Col xs="9" className="leftPannel">
                  {(filePDF != null) & (minifiedPdf == false) ? (
                    <div>
                      <PdfViewerAdvisor
                        file={filePDF}
                        actionPageChange={actionPageChange}
                        actionZoomPdf={actionZoomPdf}
                        actionRotatePdf={actionRotatePdf}
                        actionPdfScroll={actionPdfScroll}
                        actionClosePdf={actionClosePdf}
                        actionAddToHistory={actionAddToHistory}
                        setWhoCanSelect={setWhoCanSelect}
                        actionMinimizePdf={actionMinimizePdf}
                        isAdvisorSelection={isAdvisorSelection}
                        triggerSendPdfToCustomer={triggerSendPdfToCustomer}
                        isAdvisorConnected={isAdvisorConnected}
                        pageNumber={pdfPageNumber}
                        rotate={pdfRotate}
                        showMouse={showMouse}
                        isMouse={isMouse}
                      ></PdfViewerAdvisor>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xs="3" className="paddingTopLogoLeftPannel">
                  <img
                    style={{ width: "100%", marginBottom: "10px" }}
                    src={unicreditLogo}
                  />

                  <div className="btnsGroupR4a">
                    {fileHistoryState == false ? (
                      <button
                        className="btnWrapper btnListElement"
                        onClick={(e) => openFileHistory()}
                      >
                        <img
                          className="iconElement clickableImg"
                          src={fileHistoryClosed}
                          style={{ display: "block" }}
                          data-html2canvas-ignore
                          alt="Open File History"
                        />
                        <span className="chatTextClosed">File History</span>
                        {filesList.length != 0 ? (
                          <Badge color="primary" className="fileHistoryBadge">
                            {filesList.length}
                          </Badge>
                        ) : (
                          ""
                        )}
                      </button>
                    ) : (
                      <button
                        className="btnWrapper btnListElement"
                        onClick={(e) => closeFileHistory()}
                      >
                        <img
                          className="iconElement clickableImg"
                          src={fileHistoryOpened}
                          style={{ display: "block" }}
                          data-html2canvas-ignore
                          alt="Close File History"
                        />
                        <span className="chatTextOpened">File History</span>
                      </button>
                    )}
                  </div>

                  <div className="wrapperTextWaitingCustomer">
                    <p className="text-center">
                      Waiting for Customer to connect
                    </p>
                  </div>

                  {fileHistoryState == true ? (
                    <FilesHistory
                      filesList={filesList}
                      openArchivePdfFile={openArchivePdfFile}
                      closeFileHistory={closeFileHistory}
                    />
                  ) : (
                    ""
                  )}
                  <div className="emptyDivFlexGrow"></div>

                  <div className="callButtonsSectionFlex">
                    <div className="callButtonsSectionInner">
                      {filePDF == null ? (
                        <>
                          <label for="actual-btn">
                            <img
                              className="callButtonElementImg clickableImg"
                              src={uploadFile}
                              alt="Upload File"
                            />
                          </label>
                          <input
                            id="actual-btn"
                            onChange={onFileChange}
                            type="file"
                            hidden
                          />
                        </>
                      ) : (
                        <>
                          <label for="actual-btn">
                            <img
                              className="callButtonElementImg clickableImg"
                              src={uploadFileDisabled}
                              alt="Upload File"
                            />
                          </label>
                          <input id="actual-btn" type="file" hidden disabled />
                        </>
                      )}

                      <img
                        className="callButtonElementImg clickableImg"
                        src={micDisabled}
                        alt="Open Mic"
                      />
                      <img
                        className="callButtonElementImg clickableImg"
                        src={videoDisabled}
                        alt="Open Video"
                      />
                      <img
                        className="callButtonElementImg clickableImg"
                        src={closeCall}
                        alt="Close Call"
                        onClick={(e) => {
                          toggleModalCloseCall();
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </>
      ) : (
        <div className="Advisor" ref={advisorMainDiv}>
          <Container style={{ maxWidth: "100%" }}>
            <Row>
              <Col xs="9" className="leftPannel">
                {(filePDF != null) & (minifiedPdf == false) ? (
                  <div>
                    <PdfViewerAdvisor
                      file={filePDF}
                      actionPageChange={actionPageChange}
                      actionZoomPdf={actionZoomPdf}
                      actionRotatePdf={actionRotatePdf}
                      actionPdfScroll={actionPdfScroll}
                      actionClosePdf={actionClosePdf}
                      actionAddToHistory={actionAddToHistory}
                      setWhoCanSelect={setWhoCanSelect}
                      actionMinimizePdf={actionMinimizePdf}
                      isAdvisorSelection={isAdvisorSelection}
                      triggerSendPdfToCustomer={triggerSendPdfToCustomer}
                      isAdvisorConnected={isAdvisorConnected}
                      pageNumber={pdfPageNumber}
                      rotate={pdfRotate}
                      showMouse={showMouse}
                      isMouse={isMouse}
                      actionStopPdfShare={actionStopPdfShare}
                    ></PdfViewerAdvisor>
                  </div>
                ) : (
                  ""
                )}
                <video
                  ref={remoteVideo}
                  playsInLine
                  className="leftPannelBigVideo"
                ></video>
                <img
                  src={stoppedVideoImg}
                  ref={remoteVideoImg}
                  className="noVideoLeftImg"
                  style={{ display: "none" }}
                />
              </Col>
              <Col xs="3" className="paddingTopLogoLeftPannel">
                <img
                  style={{ width: "100%", marginBottom: "10px" }}
                  src={unicreditLogo}
                />
                <div className="btnsGroupR4a">
                  {chatState == false ? (
                    <button
                      className="btnWrapper btnListElement"
                      onClick={(e) => openChat()}
                    >
                      <img
                        className="iconElement clickableImg"
                        src={chatImgClosed}
                        style={{ display: "block" }}
                        data-html2canvas-ignore
                        alt="Open Chat"
                        ref={chatIcon}
                      />
                      <span className="chatTextClosed">Chat section</span>
                      {chatMessages.filter((msg) => msg.seen === false)
                        .length != 0 ? (
                        <Badge color="primary" className="fileHistoryBadge">
                          {
                            chatMessages.filter((msg) => msg.seen === false)
                              .length
                          }
                        </Badge>
                      ) : (
                        ""
                      )}
                    </button>
                  ) : (
                    <button
                      className="btnWrapper btnListElement"
                      onClick={(e) => closeChat()}
                    >
                      <img
                        className="iconElement clickableImg"
                        src={chatImgOpened}
                        style={{ display: "block" }}
                        data-html2canvas-ignore
                        alt="Close Chat"
                        ref={chatIcon}
                      />
                      <span className="chatTextOpened">Chat section</span>
                      {chatMessages.filter((msg) => msg.seen === false)
                        .length != 0 ? (
                        <Badge color="primary" className="fileHistoryBadge">
                          {
                            chatMessages.filter((msg) => msg.seen === false)
                              .length
                          }
                        </Badge>
                      ) : (
                        ""
                      )}
                    </button>
                  )}

                  {fileHistoryState == false ? (
                    <button
                      className="btnWrapper"
                      onClick={(e) => openFileHistory()}
                    >
                      <img
                        className="iconElement clickableImg"
                        src={fileHistoryClosed}
                        style={{ display: "block" }}
                        data-html2canvas-ignore
                        alt="Open File History"
                      />
                      <span className="chatTextClosed">File History</span>
                      {filesList.length != 0 ? (
                        <Badge color="primary" className="fileHistoryBadge">
                          {filesList.length}
                        </Badge>
                      ) : (
                        ""
                      )}
                    </button>
                  ) : (
                    <button
                      className="btnWrapper"
                      onClick={(e) => closeFileHistory()}
                    >
                      <img
                        className="iconElement clickableImg"
                        src={fileHistoryOpened}
                        style={{ display: "block" }}
                        data-html2canvas-ignore
                        alt="Close File History"
                      />
                      <span className="chatTextOpened">File History</span>
                    </button>
                  )}
                </div>

                <div ref={smallVideosWrapper}>
                  <video
                    ref={myVideo}
                    muted="true"
                    playsInLine
                    className="smallVideoClass"
                  ></video>
                  <img
                    src={stoppedVideoImg}
                    ref={myVideoImg}
                    className="noVideoSelfImg"
                    style={{ display: "none" }}
                  />
                  <video
                    ref={remoteVideoSmall}
                    playsInLine
                    className="smallVideoClass"
                    style={{ display: "none" }}
                  ></video>
                  <img
                    src={stoppedVideoImg}
                    ref={remoteVideoImgSmall}
                    className="noVideoSelfImg"
                    style={{ display: "none" }}
                  />
                </div>

                {fileHistoryState == true ? (
                  <FilesHistory
                    filesList={filesList}
                    openArchivePdfFile={openArchivePdfFile}
                    closeFileHistory={closeFileHistory}
                  />
                ) : (
                  ""
                )}
                {IS_WEBEX == true ? (
                  <div
                    id="message-webex-widget"
                    ref={chatMessageWidget}
                    data-html2canvas-ignore
                    style={{ left: "3%", display: "none" }}
                  ></div>
                ) : (
                  <div style={{ display: "none" }} ref={chatMessageWidget}>
                    <Chat
                      closeChat={closeChat}
                      chatMessages={chatMessages}
                      sendMessage={sendMessage}
                      name={advisorName}
                      isAdvisor={true}
                    ></Chat>
                  </div>
                )}
                <div className="emptyDivFlexGrow"></div>
                <div className="callButtonsSectionFlex">
                  <div className="callButtonsSectionInner">
                    {filePDF == null ? (
                      <>
                        <label for="actual-btn">
                          <img
                            className="callButtonElementImg clickableImg"
                            src={uploadFile}
                            alt="Upload File"
                          />
                        </label>
                        <input
                          id="actual-btn"
                          onChange={onFileChange}
                          type="file"
                          hidden
                        />
                      </>
                    ) : (
                      <>
                        <label for="actual-btn">
                          <img
                            className="callButtonElementImg clickableImg"
                            src={uploadFileDisabled}
                            alt="Upload File"
                          />
                        </label>
                        <input id="actual-btn" type="file" hidden disabled />
                      </>
                    )}

                    {micState == true ? (
                      <img
                        className="callButtonElementImg clickableImg"
                        src={micOpen}
                        alt="Close Mic"
                        onClick={(e) => stopSendAudio()}
                      />
                    ) : (
                      <img
                        className="callButtonElementImg clickableImg"
                        src={micClosed}
                        alt="Open Mic"
                        onClick={(e) => startSendAudio()}
                      />
                    )}
                    {videoStateBool == true ? (
                      <img
                        className="callButtonElementImg clickableImg"
                        src={videoOpen}
                        alt="Close Video"
                        onClick={(e) => stopSendVideo()}
                      />
                    ) : (
                      <img
                        className="callButtonElementImg clickableImg"
                        src={videoClosed}
                        alt="Open Video"
                        onClick={(e) => startSendVideo()}
                      />
                    )}
                    <img
                      className="callButtonElementImg clickableImg"
                      src={closeCall}
                      alt="Close Call"
                      onClick={(e) => {
                        toggleModalCloseCall();
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      <div
        id="circleMouse"
        ref={advisorMouseRef}
        style={{ display: !isMouse ? "none" : "" }}
      ></div>
    </div>
  );
}

export default Advisor;
